import { AxiosResponse } from 'axios';
import { PeriodicReservationType } from '../model/PeriodicReservation';
import requestPost from './helper/requestPost';
import { TimeRange } from '../model/Time';

export interface RequestBody {
  clubId: number;
  startDate: string;
  endDate: string;
  reservations: PeriodicReservationPerCourt[];
  userName: string;
  userPhoneNumber: string;
  title: string;
}

interface PeriodicReservationPerCourt {
  courtId: number;
  repeatInterval: number;
  type: PeriodicReservationType;
  reservationTimeRangeList: TimeRange[];
  daysOfWeek: number[];
}

const createPeriodicReservation = (requestBody: RequestBody): Promise<AxiosResponse<null>> => {
  const endpoint = `/admin/periodic-reservations`;
  return requestPost<RequestBody, null>(endpoint, {
    ...requestBody,
  });
};

export default createPeriodicReservation;
