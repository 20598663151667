import React, { useCallback, useState } from 'react';
import { Box, CssBaseline, SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ClubSelect from './ClubSelect';

const ClubCourtManagement = () => {
  const { courtsByClub: courtsByClubList, isFetching: isFetchingClubCourts } = useSelector(
    (s: RootState) => s.ClubCourtReducer,
  );
  const [selectedClubIndex, setSelectedClubIndex] = useState(0);

  const onChangeClub = useCallback((event: SelectChangeEvent) => {
    setSelectedClubIndex(parseInt(event.target.value, 10));
  }, []);

  if (isFetchingClubCourts) {
    // TODO: 로딩 처리
    return <Box>isLoading</Box>;
  }

  if (!courtsByClubList.length) {
    // TODO: 관리하는 구장이 없을 경우 예외 처리
    return <Box>관리하는 구장이 없습니다</Box>;
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        px: 6,
        py: 3,
        // NOTE: 화면 사이즈가 너무 작아지면 UI 가 깨지기 때문에 최소 너비 잡아놓음
        // 나중에 모바일 UI는 따로 대응 필요
        minWidth: 768,
      }}
    >
      <CssBaseline />
      {/* 구장 선택 영역 */}
      <Box sx={{ width: 200 }}>
        <ClubSelect
          courtByClubList={courtsByClubList}
          selectedClubIndex={selectedClubIndex}
          onChange={onChangeClub}
        />
      </Box>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', pt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1.6,
            flexDirection: 'row',
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1.2,
              backgroundColor: 'white',
              mr: 1,
              borderRadius: 5,
              boxShadow: '0px 0px 4px rgba(0,0,0,0.25)',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flex: 2,
              backgroundColor: 'white',
              ml: 1,
              borderRadius: 5,
              boxShadow: '0px 0px 4px rgba(0,0,0,0.25)',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '0px 0px 4px rgba(0,0,0,0.25)',
          }}
        />
      </Box>
    </Box>
  );
};
export default ClubCourtManagement;
