import { Autocomplete } from '@mui/material';
import * as React from 'react';
import { AutocompleteValue } from '@mui/core/AutocompleteUnstyled/useAutocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { ManagerUser } from '../../model/ManagerUser';

export interface ManagerUserAutoCompleteProps {
  managerUserList: ManagerUser[];
  handleSelectedManagerUser: (managerUser: ManagerUser | null) => void;
}

const ManagerUserAutoComplete = ({
  managerUserList,
  handleSelectedManagerUser,
}: ManagerUserAutoCompleteProps): null | JSX.Element => {
  const [lessonManagerUserName, setLessonManagerUserName] = useState<string>('');

  const handleLessonManagerUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLessonManagerUserName(event.target.value);
  };

  const handleAutoCompleteOptionSelectChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<any, any, any, any>,
  ) => {
    const managerUser = managerUserList.find((it) => it.id === value.managerUserId);
    if (managerUser) {
      handleSelectedManagerUser(managerUser);
    }
  };

  const getOptionElement = (props: any, option: any) => (
    <Box
      component="li"
      key={option.managerUserId}
      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
      {...props}
    >
      <img width="20" src={`${option.photoPathUrl}`} alt="" />
      {option.label}
    </Box>
  );

  return (
    <Autocomplete
      fullWidth
      disablePortal
      onChange={handleAutoCompleteOptionSelectChange}
      options={managerUserList.map((managerUser) => {
        return {
          label: managerUser.name,
          photoPathUrl: managerUser.profilePhotoPath,
          managerUserId: managerUser.id,
        };
      })}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={getOptionElement}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          id="standard-required"
          variant="standard"
          inputProps={{
            ...params.inputProps,
            type: 'text',
          }}
          value={lessonManagerUserName}
          onChange={handleLessonManagerUserNameChange}
          label="레슨 담당자"
          fullWidth
        />
      )}
    />
  );
};

export default ManagerUserAutoComplete;
