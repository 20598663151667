import { AxiosResponse } from 'axios';
import requestPatch from './helper/requestPatch';

const removeLessonFromReservation = (reservationId: number): Promise<AxiosResponse<void>> => {
  const endpoint = `/admin/reservations/${reservationId}/unlessonify`;

  return requestPatch<void, void>(endpoint);
};

export default removeLessonFromReservation;
