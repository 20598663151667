import { AxiosResponse } from 'axios';
import requestPost from './helper/requestPost';
import { TimeRange } from '../model/Time';

interface RequestBody {
  clubId: number;
  courtId: number;
  date: string;
  reservationTimeRangeList: TimeRange[];
  title: string;
  userName: string;
  userPhoneNumber: string;
}

interface ResponseBody {
  id: number;
}

const createDailyReservation = (requestBody: RequestBody): Promise<AxiosResponse<ResponseBody>> => {
  const endpoint = '/admin/reservations';

  return requestPost<RequestBody, ResponseBody>(endpoint, {
    ...requestBody,
  });
};

export default createDailyReservation;
