import { AxiosResponse } from 'axios';
import requestPost from './helper/requestPost';

interface RequestBody {
  accountId: string;
  password: string;
}

export interface LoginResult {
  accessToken: string;
  refreshToken: string;
}

const postSignin = (accountId: string, password: string): Promise<AxiosResponse<LoginResult>> => {
  const endpoint = '/admin/login';

  return requestPost<RequestBody, LoginResult>(endpoint, {
    accountId,
    password,
  });
};
export default postSignin;
