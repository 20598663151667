import { CourtsByClub } from '../model/Court';
import requestGet from './helper/requestGet';

const getMyclub = async (): Promise<CourtsByClub[]> => {
  const endpoint = `/admin/clubs/my`;

  return requestGet<CourtsByClub[]>(endpoint).then((data) => data.data);
};

export default getMyclub;
