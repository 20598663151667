import * as React from 'react';
import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import moment, { Moment } from 'moment';
import { Tooltip } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CalendarPicker, LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/lab';
import { Theme } from '@mui/material/styles';
import { PickersDayProps } from '@mui/lab/PickersDay/PickersDay';
import { RootState } from '../../store';
import Court, { CourtsByClub } from '../../model/Court';
import {
  getDefaultReservationTimeBlock,
  getReservationTimeBlocksFromIndex,
} from '../../helper/getReservationTimeBlock';
import { TimeBlock } from '../../model/Time';
import ReservationTimeButton from '../../components/Button/ReservationTimeButton';
import { compareNumberAscending } from '../../helper/compareHelper';
import getPeriodicReservationPreview from '../../api/getPeriodicReservationPreview';
import { PeriodicReservationPreview } from '../../model/PeriodicReservation';
import { dateStringToMoment, getWeekCntOfMonth, momentToDateString } from '../../helper/dateHelper';
import { DuplicateReservationTimeException } from '../../model/Exception';
import blockPeriodicReservation from '../../api/blockPeriodicReservation';
import Club from '../../model/Club';

interface Props {
  open: boolean;
  toggleModal: () => void;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void; // For Refresh Usage
  selectedClub: Club;
}

const minDate = moment('2022-01-01T00:00:00.000');

const daysName = ['월', '화', '수', '목', '금', '토', '일'];

export default function CreatePeriodicReservationBlockModal({
  open,
  toggleModal,
  refreshReservationsAndLessonsOfClub,
  selectedClub,
}: Props) {
  const timeBlocks: TimeBlock[] = getDefaultReservationTimeBlock();

  const { courtsByClub } = useSelector((s: RootState) => s.ClubCourtReducer);
  const [selectedCourtsByClub, setSelectedCourtsByClub] = useState<CourtsByClub>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    courtsByClub.find((it) => it.club.id === selectedClub.id)!,
  );
  const [selectedCourt, setSelectedCourt] = useState<Court>(selectedCourtsByClub.courts[0]);
  const [isClubSelectDialogOpen, setIsClubSelectDialogOpen] = useState<boolean>(false);
  const [isCourtSelectDialogOpen, setIsCourtSelectDialogOpen] = useState<boolean>(false);

  /**
   * 모달 Input
   */
  const [title, setTitle] = useState<string>('정기 예약 금지 일정');

  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());

  const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] = useState<boolean>(false);
  const [weekCntOfStartDateCalendarMonth, setWeekCntOfStartDateCalendarMonth] = useState<number>(5);

  const [isEndDateCalendarOpen, setIsEndDateCalendarOpen] = useState<boolean>(false);
  const [weekCntOfEndDateCalendarMonth, setWeekCntOfEndDateCalendarMonth] = useState<number>(5);

  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<number[]>([]);
  const [tempSelectedDaysOfWeek, setTempSelectedDaysOfWeek] = useState<number[]>([]);

  const [isRepeatSelectorOpen, setIsRepeatSelectorOpen] = useState<boolean>(false);

  const [selectedTimeBlockIndexList, setSelectedTimeBlockIndexList] = useState<number[]>([]);
  const [tempSelectedTimeBlockIndexList, setTempSelectedTimeBlockIndexList] = useState<number[]>(
    [],
  );
  const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);

  const [isInputPageShown, setIsInputPageShown] = useState(true);
  const [previews, setPreviews] = useState<PeriodicReservationPreview[] | null>([]);

  const handleClose = () => toggleModal();

  const onClubSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetClubId = Number((event.target as HTMLInputElement).value);
    const targetCourtsByClub = courtsByClub.find((it) => it.club.id === targetClubId);
    if (targetCourtsByClub) {
      setSelectedCourtsByClub(targetCourtsByClub);
      setSelectedCourt(targetCourtsByClub.courts[0]);
    }
    setIsClubSelectDialogOpen(false);
  };

  const onCourtSelectChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const targetCourtId = Number((event.target as HTMLInputElement).value);
      const targetCourt = selectedCourtsByClub.courts.find((it) => it.id === targetCourtId);

      if (targetCourt) {
        setSelectedCourt(targetCourt);
      }

      setIsCourtSelectDialogOpen(false);
    } catch (e) {
      console.log(e);
      alert('네트워크 오류 - 코트를 다시 선택해주세요');
    }
  };

  const handleEndDateSelectEvent = async (date: Moment | null) => {
    if (date) {
      setEndDate(date);
    }
    handleEndDateButtonClick();
  };

  const handleStartDateSelectEvent = async (date: Moment | null) => {
    if (date) {
      setStartDate(date);
      if (date.isAfter(endDate)) {
        setEndDate(date);
      }
    }
    handleStartDateButtonClick();
  };

  const onNextButtonClick = async () => {
    /**
     * TODO : 입력 다 잘 채우기 전까지 넘어가지 않음
     */
    if (title.length === 0) {
      alert('일정 제목을 입력해주세요.');
      return;
    }
    if (selectedDaysOfWeek.length === 0) {
      alert('반복 방식을 선택해주세요.');
      return;
    }

    if (selectedTimeBlockIndexList.length === 0) {
      alert('시간을 선택해주세요.');
      return;
    }

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocks,
      selectedTimeBlockIndexList,
    );

    const startDateString = momentToDateString(startDate);
    const endDateString = momentToDateString(endDate);

    const response = await getPeriodicReservationPreview({
      clubId: selectedCourtsByClub.club.id,
      startDate: startDateString,
      endDate: endDateString,
      reservations: [
        {
          courtId: selectedCourt.id,
          repeatInterval: 1,
          daysOfWeek: selectedDaysOfWeek,
          type: 'WEEKLY',
          reservationTimeRangeList: [
            {
              startTime: selectedTimeBlocks[0].startTime,
              endTime: selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime,
            },
          ],
        },
      ],
    });

    if (response.status !== 200) {
      alert('서버 오류 발생. 죄송합니다.');
      console.log(response.data);
      return;
    }

    if (response.data.previews[0].reservationDateTimes.length === 0) {
      alert(
        `${startDateString} ~ ${endDateString} 간 반복 조건에 맞는 날짜가 없습니다. 다시 확인해주세요.`,
      );
      return;
    }
    setPreviews(response.data.previews);

    setIsInputPageShown((prev) => !prev);
  };

  const handleStartDateButtonClick = () => {
    setIsStartDateCalendarOpen((prevState) => !prevState);
  };

  const handleEndDateButtonClick = () => {
    setIsEndDateCalendarOpen((prevState) => !prevState);
  };

  const onTimeSelect = (index: number) => () => {
    setTempSelectedTimeBlockIndexList((prevList) => {
      if (prevList.includes(index)) {
        const indexOfSelectedIndex = prevList.findIndex((listItem) => listItem === index);
        if (indexOfSelectedIndex === 0) {
          return prevList.slice(1);
        }
        if (indexOfSelectedIndex === prevList.length - 1) {
          return prevList.slice(0, prevList.length - 1);
        }
        return [...prevList];
      }
      if (prevList.length >= 1) {
        if (
          Math.abs(prevList[0] - index) === 1 ||
          Math.abs(prevList[prevList.length - 1] - index) === 1
        ) {
          const nextList = [...prevList, index];
          nextList.sort(compareNumberAscending);
          return nextList;
        }
      }

      return [index];
    });
  };

  const getRepeatButtonContent = (): string => {
    if (selectedDaysOfWeek.length === 0) {
      return '선택 없음';
    }

    let content = '매 주  ';
    selectedDaysOfWeek.sort().forEach((dayOfWeek) => {
      content += `${daysName[dayOfWeek - 1]}요일`;
      if (dayOfWeek !== selectedDaysOfWeek[selectedDaysOfWeek.length - 1]) {
        content += ', ';
      }
    });

    return content;
  };

  const getTimeSelectButtonContent = (): string => {
    if (selectedTimeBlockIndexList.length === 0) {
      return '선택 없음';
    }

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocks,
      selectedTimeBlockIndexList,
    );

    return `${selectedTimeBlocks[0].startTime}-${
      selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime
    }`;
  };

  const handleRepeatButtonClick = () => {
    setIsRepeatSelectorOpen((prevState) => !prevState);
  };

  const handleTimeButtonClick = () => {
    setIsTimePickerOpen((prevState) => !prevState);
  };

  const handleTimeBlockCancelButtonClick = () => {
    setIsTimePickerOpen(false);
    setTempSelectedTimeBlockIndexList(selectedTimeBlockIndexList);
  };

  const handleTimeBlockConfirmButtonClick = () => {
    setSelectedTimeBlockIndexList(tempSelectedTimeBlockIndexList);
    setIsTimePickerOpen(false);
  };

  const handleTitleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDayOfWeekButtonClick = (dayOfWeek: number) => () => {
    if (tempSelectedDaysOfWeek.find((it) => it === dayOfWeek)) {
      // selected date
      setTempSelectedDaysOfWeek((prev) => prev.filter((it) => it !== dayOfWeek));
    } else {
      setTempSelectedDaysOfWeek((prev) => [...prev, dayOfWeek]);
    }
  };

  const handleRepeatCancelButtonClick = () => {
    setTempSelectedDaysOfWeek([]);
    handleRepeatButtonClick();
  };

  const handleRepeatConfirmButtonClick = () => {
    setSelectedDaysOfWeek(tempSelectedDaysOfWeek);
    handleRepeatButtonClick();
  };

  const onPreviousButtonClick = () => {
    setIsInputPageShown((prev) => !prev);
  };

  const onCreateButtonClick = async () => {
    const startDateString = momentToDateString(startDate);
    const endDateString = momentToDateString(endDate);

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocks,
      selectedTimeBlockIndexList,
    );

    try {
      await blockPeriodicReservation({
        clubId: selectedCourtsByClub.club.id,
        startDate: startDateString,
        endDate: endDateString,
        title,
        reservations: [
          {
            courtId: selectedCourt.id,
            repeatInterval: 1,
            daysOfWeek: selectedDaysOfWeek,
            type: 'WEEKLY',
            reservationTimeRangeList: [
              {
                startTime: selectedTimeBlocks[0].startTime,
                endTime: selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime,
              },
            ],
          },
        ],
      });

      await refreshReservationsAndLessonsOfClub(selectedCourtsByClub.club.id, startDate);
      toggleModal();
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        alertDuplicateReservationTime(e);
        return;
      }
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  const handleStartDateSelectCalendarMonthChange = (date: moment.Moment) => {
    setWeekCntOfStartDateCalendarMonth(getWeekCntOfMonth(date));
  };

  const handleEndDateSelectCalendarMonthChange = (date: moment.Moment) => {
    setWeekCntOfEndDateCalendarMonth(getWeekCntOfMonth(date));
  };

  const renderPickerDay = (
    date: Moment,
    _selectedDates: Array<Moment | null>,
    pickersDayProps: PickersDayProps<Moment>,
  ) => {
    const dateString = momentToDateString(date);
    const selected = !!previews?.find((preview) =>
      preview.reservationDateTimes.find((dateTime) => dateTime.date === dateString),
    );

    return (
      <PickersDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        style={
          selected
            ? {
                backgroundColor: '#6200EE',
                color: '#FFFFFF',
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
              }
            : undefined
        }
      />
    );
  };

  useEffect(() => {
    if (isTimePickerOpen) {
      if (selectedTimeBlockIndexList.length !== 0) {
        scrollToSelectedTimeBlocks();
      } else {
        scrollToNextTimeBlock();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimePickerOpen]);

  const scrollToSelectedTimeBlocks = () => {
    const selectedTimeBlock =
      timeBlocks[
        selectedTimeBlockIndexList[Math.ceil((selectedTimeBlockIndexList.length - 1) / 2)]
      ];

    const timeBlockEl = document.getElementById(`reservation_time_${selectedTimeBlock.startTime}`);

    if (timeBlockEl) {
      timeBlockEl.scrollIntoView({ inline: 'center' });
    }
  };

  const scrollToNextTimeBlock = () => {
    const curMoment = moment();
    let curHour = curMoment.hour();
    let curMinute = curMoment.minute();
    if (curMinute > 30) {
      curHour += 1;
      curMinute = 0;
    } else {
      curMinute = 30;
    }

    const curHourInString = curHour < 10 ? `0${curHour}` : curHour;
    const curMinuteInString = curMinute < 10 ? `0${curMinute}` : curMinute;

    const timeBlockEl = document.getElementById(
      `reservation_time_${curHourInString}:${curMinuteInString}`,
    );

    if (timeBlockEl) {
      timeBlockEl.scrollIntoView({ inline: 'center' });
    }
  };

  const alertDuplicateReservationTime = (e: AxiosError) => {
    // Note: e.response.data.message 값이 존재하지 않는 경우에는 날짜를 뭉뚱그려서 표현
    if (!e.response?.data?.message) {
      alert('해당 날짜에 이미 일정이 존재합니다\n확인해주세요🤔');
      return;
    }

    const duplicateReservationTime: DuplicateReservationTimeException = JSON.parse(
      e.response.data.message,
    );
    const duplicateDate = dateStringToMoment(duplicateReservationTime.date);
    let alertMessage = `${duplicateDate.month()}월 ${duplicateDate.days()}일 ${
      duplicateReservationTime.startTime
    }에 이미 일정이 존재합니다.\n확인해주세요🤔`;
    if (moment().year() !== duplicateDate.year()) {
      alertMessage = `${duplicateDate.year()}년 ${alertMessage}`;
    }
    alert(alertMessage);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: '30px' } }}
      >
        <Box sx={{ width: 400, padding: '27px' }}>
          {isInputPageShown ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>정기 예약 금지</Box>
              </Box>
              <Box style={{ marginTop: '32px' }}>
                <TextField
                  required
                  id="standard-required"
                  label="일정 제목"
                  onChange={handleTitleTextChange}
                  value={title}
                  variant="standard"
                  fullWidth
                  sx={{ fontSize: '20px' }}
                />
                <Box sx={{ marginTop: '20px', flexDirection: 'column' }}>
                  <Box sx={{ position: 'relative' }}>
                    <Button
                      disabled
                      // TODO : disabled 의 제거 = 일일 일정 생성에서 구장 변경을 허용할 경우, 추가적인 UX 를 고려해야함
                      onClick={() => setIsClubSelectDialogOpen(true)}
                      sx={{ ...buttonStyle, mr: '15px' }}
                    >
                      {selectedCourtsByClub.club.name}
                    </Button>
                    <Button
                      onClick={() => setIsCourtSelectDialogOpen(true)}
                      sx={isCourtSelectDialogOpen ? selectedButtonStyle : buttonStyle}
                    >
                      {selectedCourt.name}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>시작 날짜</Box>

                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Button
                        onClick={handleStartDateButtonClick}
                        sx={isStartDateCalendarOpen ? selectedButtonStyle : buttonStyle}
                      >
                        {startDate.locale('ko').format('yyyy. MM. DD (dd)')}
                      </Button>
                    </Box>
                  </Box>
                  {isStartDateCalendarOpen && (
                    <Box
                      sx={{
                        marginTop: '19px',
                      }}
                    >
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <CalendarPicker
                          minDate={minDate}
                          date={startDate}
                          onChange={handleStartDateSelectEvent}
                          onMonthChange={handleStartDateSelectCalendarMonthChange}
                          views={['day']}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  <Box
                    sx={
                      // eslint-disable-next-line no-nested-ternary
                      getBoxStyleUnderCalendar(
                        isStartDateCalendarOpen,
                        weekCntOfStartDateCalendarMonth,
                      )
                    }
                  >
                    <Box sx={{ width: '50%' }}>종료 날짜</Box>

                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Button
                        onClick={handleEndDateButtonClick}
                        sx={isEndDateCalendarOpen ? selectedButtonStyle : buttonStyle}
                      >
                        {endDate.locale('ko').format('yyyy. MM. DD (dd)')}
                      </Button>
                    </Box>
                  </Box>

                  {isEndDateCalendarOpen && (
                    <Box
                      sx={{
                        marginTop: '19px',
                      }}
                    >
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <CalendarPicker
                          minDate={startDate}
                          date={endDate}
                          onChange={handleEndDateSelectEvent}
                          onMonthChange={handleEndDateSelectCalendarMonthChange}
                          views={['day']}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}

                  <Box
                    sx={getBoxStyleUnderCalendar(
                      isEndDateCalendarOpen,
                      weekCntOfEndDateCalendarMonth,
                    )}
                  >
                    <Box style={{ width: '50%' }}>반복</Box>
                    <Box style={{ position: 'absolute', right: 0 }}>
                      <Button
                        sx={getRepeatButtonStyle(isRepeatSelectorOpen, selectedDaysOfWeek)}
                        onClick={handleRepeatButtonClick}
                      >
                        {getRepeatButtonContent()}
                      </Button>
                    </Box>
                  </Box>

                  {isRepeatSelectorOpen && (
                    <Box sx={{ marginTop: '19px' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip title="현재 매 주 반복만 지원합니다" placement="top-start">
                          <Box
                            sx={{
                              ...buttonLikeBoxStyle,
                              border: 'solid 0.7px gray',
                              color: 'gray',
                              width: '100%',
                              fontWeight: 300,
                            }}
                          >
                            매 주마다
                          </Box>
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}
                      >
                        {daysName.map((dayName, idx) => {
                          // Day Of Week : 월요일 = 1, 화요일 = 2, ..
                          const isSelected = tempSelectedDaysOfWeek.find((it) => it === idx + 1);
                          return (
                            <Button
                              onClick={handleDayOfWeekButtonClick(idx + 1)}
                              key={dayName}
                              sx={
                                isSelected
                                  ? { minWidth: '32px', color: '3182F5', fontWeight: 700 }
                                  : { minWidth: '32px', color: '#000000', fontWeight: 300 }
                              }
                            >
                              {dayName}
                            </Button>
                          );
                        })}
                      </Box>
                      <Box sx={{ marginTop: '9px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleRepeatCancelButtonClick}>취소</Button>
                        <Button onClick={handleRepeatConfirmButtonClick}>확인</Button>
                      </Box>
                    </Box>
                  )}

                  <Box
                    style={
                      isRepeatSelectorOpen
                        ? {
                            marginTop: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                          }
                        : {
                            marginTop: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                          }
                    }
                  >
                    <Box style={{ width: '50%' }}>시간</Box>
                    <Box style={{ position: 'absolute', right: 0 }}>
                      <Button
                        onClick={handleTimeButtonClick}
                        sx={isTimePickerOpen ? selectedButtonStyle : buttonStyle}
                      >
                        {getTimeSelectButtonContent()}
                      </Button>
                    </Box>
                  </Box>

                  {isTimePickerOpen && (
                    <Box sx={{ marginTop: '20px' }}>
                      <Box sx={{ fontSize: '0.7em', color: '#FF6392' }}>
                        💡 연속되지 않은 시간 선택 시, 이전 선택된 시간들은 선택 해제됩니다.
                      </Box>
                      <Box sx={{ marginTop: '10px', display: 'flex', overflowX: 'scroll' }}>
                        {timeBlocks.map((timeBlock: TimeBlock, index: number) => {
                          const { startTime, disabled } = timeBlock;
                          return (
                            <ReservationTimeButton
                              key={`reservation_time_${startTime}`}
                              id={`reservation_time_${startTime}`}
                              timeText={`${startTime}`}
                              disabled={disabled}
                              selected={tempSelectedTimeBlockIndexList.includes(index)}
                              onPress={onTimeSelect(index)}
                            />
                          );
                        })}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleTimeBlockCancelButtonClick}>취소</Button>
                        <Button onClick={handleTimeBlockConfirmButtonClick}>확인</Button>
                      </Box>
                    </Box>
                  )}

                  <Box style={{ flexDirection: 'row', marginTop: '30px', position: 'relative' }}>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#F2F3F5',
                        color: '#000000',
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      취소
                    </Button>
                    <Button
                      variant="contained"
                      onClick={onNextButtonClick}
                      color="warning"
                      sx={{
                        position: 'absolute',
                        right: 0,
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      다음
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>정기 일정 미리보기</Box>
              </Box>
              <Box style={{ marginTop: '32px' }}>
                <Box
                  sx={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ width: '50%' }}>제목</Box>
                  <Box sx={{ width: '50%', textAlign: 'end' }}>{title}</Box>
                </Box>
                <Box sx={{ marginTop: '20px', flexDirection: 'column' }}>
                  <Box sx={{ position: 'relative', display: ' flex' }}>
                    <Box
                      sx={{
                        ...buttonLikeBoxStyle,
                        mr: '15px',
                      }}
                    >
                      {selectedCourtsByClub.club.name}
                    </Box>

                    <Box sx={buttonLikeBoxStyle}>{selectedCourt.name}</Box>
                  </Box>

                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>시작 날짜</Box>

                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Box
                        sx={{
                          ...buttonLikeBoxStyle,
                        }}
                      >
                        {startDate.locale('ko').format('yyyy. MM. DD (dd)')}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>종료 날짜</Box>

                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Box
                        sx={{
                          ...buttonLikeBoxStyle,
                        }}
                      >
                        {endDate.locale('ko').format('yyyy. MM. DD (dd)')}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>반복</Box>

                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Box
                        sx={
                          selectedDaysOfWeek.length >= 3
                            ? { ...buttonLikeBoxStyle, fontSize: '0.6em' }
                            : buttonLikeBoxStyle
                        }
                      >
                        {getRepeatButtonContent()}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box style={{ width: '50%' }}>시간</Box>
                    <Box style={{ position: 'absolute', right: 0 }}>
                      <Box
                        sx={{
                          ...buttonLikeBoxStyle,
                        }}
                      >
                        {getTimeSelectButtonContent()}
                      </Box>
                    </Box>
                  </Box>

                  {/**
                   Static Calendar
                   */}
                  <Box sx={{ marginTop: '14px' }}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      {!!previews?.length && (
                        <StaticDatePicker
                          value={previews[0].reservationDateTimes[0].date}
                          renderDay={renderPickerDay}
                          minDate={dateStringToMoment(previews[0].reservationDateTimes[0].date)}
                          maxDate={dateStringToMoment(
                            previews[0].reservationDateTimes[
                              previews[0].reservationDateTimes.length - 1
                            ].date,
                          )}
                          onChange={() => ({})}
                          renderInput={() => <></>}
                          views={['day']}
                          ToolbarComponent={() => <></>}
                          readOnly
                        />
                      )}
                    </LocalizationProvider>
                  </Box>

                  <Box style={{ flexDirection: 'row', marginTop: '30px', position: 'relative' }}>
                    <Button
                      variant="contained"
                      onClick={onPreviousButtonClick}
                      sx={{
                        backgroundColor: '#F2F3F5',
                        color: '#000000',
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      이전
                    </Button>
                    <Button
                      variant="contained"
                      onClick={onCreateButtonClick}
                      color="warning"
                      sx={{
                        position: 'absolute',
                        right: 0,
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      생성
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
      {isClubSelectDialogOpen && (
        <Dialog onClose={() => setIsClubSelectDialogOpen(false)} open={isClubSelectDialogOpen}>
          <DialogTitle>구장 선택</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup name="controlled-radio-buttons-group" onChange={onClubSelectChange}>
                {courtsByClub.map((it) => (
                  <FormControlLabel
                    key={it.club.id}
                    value={it.club.id}
                    control={<Radio />}
                    label={it.club.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
      {isCourtSelectDialogOpen && (
        <Dialog onClose={() => setIsCourtSelectDialogOpen(false)} open={isCourtSelectDialogOpen}>
          <DialogTitle>코트 선택</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup name="controlled-radio-buttons-group" onChange={onCourtSelectChange}>
                {selectedCourtsByClub.courts.map((court) => (
                  <FormControlLabel
                    key={court.id}
                    value={court.id}
                    control={<Radio />}
                    label={court.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

const buttonStyle: SystemStyleObject<Theme> = {
  backgroundColor: '#F2F3F5',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '15px',
  width: '165px',
  height: '44px',
  textAlign: 'center',
  color: '#000000',
};

const selectedButtonStyle: SystemStyleObject<Theme> = {
  ...buttonStyle,
  backgroundColor: '#FFFFFF',
  color: '#38B882',
  border: '0.7px solid #38B882',
};

const buttonLikeBoxStyle: SystemStyleObject<Theme> = {
  ...buttonStyle,
  backgroundColor: 'white',
  color: 'black',
  border: 'solid 1px #DDDDDD',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
  letterSpacing: '0.02857em',
  minWidth: '64px',
  padding: '6px 8px',
  borderRadius: '4px',
  display: 'flex',
};

const getBoxStyleUnderCalendar = (
  isCalendarOpen: boolean,
  weekCntOfCalendarMonth: number,
): SystemStyleObject<Theme> => {
  if (isCalendarOpen) {
    if (weekCntOfCalendarMonth === 6) {
      return {
        marginTop: '-20px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      };
    }

    return {
      marginTop: '-45px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    };
  }

  return {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  };
};

// const CustomPickersDay = styled(PickersDay, {
//   shouldForwardProp: (prop) => prop !== 'selected',
// })(({ theme, selected }) => ({
//   ...(selected && {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover, &:focus': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//     borderTopLeftRadius: '50%',
//     borderBottomLeftRadius: '50%',
//     borderTopRightRadius: '50%',
//     borderBottomRightRadius: '50%',
//   }),
// }));

const getRepeatButtonStyle = (
  isRepeatSelectorOpen: boolean,
  selectedDaysOfWeek: number[],
): SystemStyleObject<Theme> => {
  const style = { ...buttonStyle };

  if (selectedDaysOfWeek.length >= 3) {
    style.fontSize = '0.6em';
  }

  if (isRepeatSelectorOpen) {
    return {
      ...style,
      ...selectedButtonStyle,
    };
  }

  return style;
};
