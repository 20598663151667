import { AxiosResponse } from 'axios';
import requestPatch from './helper/requestPatch';

const cancelReservation = (reservationId: number): Promise<AxiosResponse<void>> => {
  const endpoint = `/admin/reservations/${reservationId}/cancel`;

  return requestPatch<void, void>(endpoint);
};

export default cancelReservation;
