import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { AccessAlarm } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { logout } from '../../store/models/login';
import { DRAWER_WIDTH } from '../../constant/common.const';
import { RouteType } from '../../model/route';
import { SIDE_BAR_ROUTE_LIST } from './Sidebar.helper';

const logoutButtonWidth = 218;

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [selectedRoute, setSelectedRoute] = useState<RouteType>();

  const handleLogoutButtonOnclick = () => {
    dispatch(logout());
    history.push('/');
  };

  const onClickRouteBtn = (route: RouteType) => () => {
    history.push(route);
  };

  useEffect(() => {
    switch (location.pathname) {
      case RouteType.ReservationManagement:
        setSelectedRoute(RouteType.ReservationManagement);
        break;
      case RouteType.ClubCourtManagement:
        setSelectedRoute(RouteType.ClubCourtManagement);
        break;
      default:
    }
  }, [location.pathname]);

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar /> {/* for spacing */}
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '20px', ml: '21px', mr: '21px' }}
        >
          <Stack direction="column">
            <Typography fontWeight="bold" fontSize="24px" lineHeight="28px">
              안녕하세요
            </Typography>
            <Typography fontWeight="bold" fontSize="24px" lineHeight="28px">
              관리자님 😊
            </Typography>
          </Stack>
          <Typography fontSize="16px" lineHeight="19px" letterSpacing="-0.03em" color="#8C93A0">
            관리자
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="warning"
          onClick={handleLogoutButtonOnclick}
          sx={{ width: logoutButtonWidth, mt: '34px', ml: '21px' }}
        >
          로그아웃 👋
        </Button>
        <Divider sx={{ mt: '20px' }} />
        <List>
          {SIDE_BAR_ROUTE_LIST.map((routeItem) => (
            <ListItem
              sx={{
                borderLeft:
                  selectedRoute === routeItem.route ? '3px #38B882 solid' : '3px transparent solid',
                cursor: 'pointer',
              }}
              key={routeItem.route}
              onClick={onClickRouteBtn(routeItem.route)}
            >
              {/* TODO: route 별 아이콘 적용 어떻게 할지? */}
              {/* <Box sx={{ width: '1px', backgroundColor: '#5664D2' }} /> */}
              <ListItemIcon>
                <AccessAlarm
                  sx={{ color: selectedRoute === routeItem.route ? '#38B882' : '#8C93A0' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      fontWeight: selectedRoute === routeItem.route ? 'bold' : 'normal',
                      fontSize: 16,
                      color: selectedRoute === routeItem.route ? '#38B882' : '#8C93A0',
                    }}
                  >
                    {routeItem.title}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
