import moment from 'moment';
import CourtDailyOperationInfo from '../model/CourtDailyOperationInfo';
import { TimeBlock, Time, TimeRange } from '../model/Time';
import getHourMinuteFromTimeFormat from './getHourMinuteFromTimeFormat';

const HOUR_TO_MINUTE_CONST = 60;

const calcNextReservationHourAndMinute = (hour: number, minute: number, interval: number) => {
  if (minute + interval >= 60) {
    return {
      reservationIntervalAddedHour: hour + 1,
      reservationIntervalAddedMinute: (minute + interval) % 60,
    };
  }
  return {
    reservationIntervalAddedHour: hour,
    reservationIntervalAddedMinute: minute + interval,
  };
};

const getStartAndEndMoment = (
  dateString: string,
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
) => {
  const startTimeString = `${`${startHour}`.padStart(2, '0')}:${`${startMinute}`.padStart(2, '0')}`;
  const endTimeString = `${`${endHour}`.padStart(2, '0')}:${`${endMinute}`.padStart(2, '0')}`;

  return {
    startMoment: moment(`${dateString}T${startTimeString}`),
    endMoment: moment(`${dateString}T${endTimeString}`),
  };
};

const isSelectedTimePreoccupied = (
  preoccupiedTime: TimeRange,
  startTime: Time,
  endTime: Time,
): boolean => {
  const [preoccupiedStartHour, preoccupiedStartMinute] = getHourMinuteFromTimeFormat(
    preoccupiedTime.startTime,
  );
  const [preoccupiedEndHour, preoccupiedEndMinute] = getHourMinuteFromTimeFormat(
    preoccupiedTime.endTime,
  );
  return (
    (preoccupiedStartHour === startTime.hour && preoccupiedStartMinute === startTime.minute) ||
    (preoccupiedEndHour === endTime.hour && preoccupiedEndMinute === endTime.minute)
  );
};

export const getReservationTimeBlockWithPreoccupied = (
  { preoccupiedReservationTimeRanges }: CourtDailyOperationInfo,
  selectedDateString: string,
): TimeBlock[] => {
  const timeBlocks: TimeBlock[] = [];

  const reservationInterval = 30;
  let [hour, minute] = [0, 0];
  const [endHour, endMinute] = [24, 0];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (
      hour * HOUR_TO_MINUTE_CONST + minute + reservationInterval >
      endHour * HOUR_TO_MINUTE_CONST + endMinute
    ) {
      break;
    }

    const { reservationIntervalAddedHour, reservationIntervalAddedMinute } =
      calcNextReservationHourAndMinute(hour, minute, reservationInterval);

    const { startMoment, endMoment } = getStartAndEndMoment(
      selectedDateString,
      hour,
      minute,
      reservationIntervalAddedHour,
      reservationIntervalAddedMinute,
    );

    const isDisabled =
      // eslint-disable-next-line no-loop-func
      !!preoccupiedReservationTimeRanges.find((preoccupiedTime) =>
        isSelectedTimePreoccupied(
          preoccupiedTime,
          { hour, minute },
          {
            hour: reservationIntervalAddedHour,
            minute: reservationIntervalAddedMinute,
          },
        ),
      );

    timeBlocks.push({
      startTime: startMoment.format('HH:mm'),
      endTime: endMoment.format('HH:mm'),
      disabled: isDisabled,
      originalPrice: 0, // 추후 적용
      price: 0, // 추후 적용
    });

    hour = reservationIntervalAddedHour;
    minute = reservationIntervalAddedMinute;
  }

  return timeBlocks;
};

export const getDefaultReservationTimeBlock = (): TimeBlock[] => {
  const timeBlocks: TimeBlock[] = [];

  const reservationInterval = 30;
  let [hour, minute] = [0, 0];
  const [endHour, endMinute] = [24, 0];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (
      hour * HOUR_TO_MINUTE_CONST + minute + reservationInterval >
      endHour * HOUR_TO_MINUTE_CONST + endMinute
    ) {
      break;
    }

    const { reservationIntervalAddedHour, reservationIntervalAddedMinute } =
      calcNextReservationHourAndMinute(hour, minute, reservationInterval);

    const curStartHour = hour;
    const curStartMinute = minute;
    const curEndHour = reservationIntervalAddedHour;
    const curEndMinute = reservationIntervalAddedMinute;
    const startTimeString = `${`${curStartHour}`.padStart(2, '0')}:${`${curStartMinute}`.padStart(
      2,
      '0',
    )}`;
    const endTimeString = `${`${curEndHour}`.padStart(2, '0')}:${`${curEndMinute}`.padStart(
      2,
      '0',
    )}`;

    timeBlocks.push({
      startTime: startTimeString,
      endTime: endTimeString,
      disabled: false,
      originalPrice: 0, // TODO
      price: 0, // TODO
    });

    hour = reservationIntervalAddedHour;
    minute = reservationIntervalAddedMinute;
  }

  return timeBlocks;
};

export const getReservationTimeBlocksFromIndex = (
  timeBlocks: TimeBlock[],
  timeBlockIndexList: number[],
): TimeRange[] => {
  if (!timeBlocks.length) {
    return [];
  }

  return timeBlockIndexList.map((index) => {
    const { startTime, endTime } = timeBlocks[index];

    return {
      startTime,
      endTime,
    };
  });
};
