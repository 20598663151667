import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container, Snackbar } from '@mui/material';
import tennisBoxLogoImg from '../../assets/images/tennis-box-logo.png';
import { login, refresh } from '../../store/models/login';
import { REFRESH_TOKEN } from '../../constant/tokenType';
import { getLocalStorage } from '../../helper/localStorage';
import { RootState } from '../../store';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, error } = useSelector((s: RootState) => s.LoginReducer);
  const [isAutoLogin, setAutoLogin] = useState(true);
  const [showLoginErrorToast, setShowLoginErrorToast] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const accountId = data.get('accountId')?.toString();
    const password = data.get('password')?.toString();

    if (!accountId || !password) {
      setShowLoginErrorToast(true);
      return;
    }

    try {
      dispatch(login(accountId, password, isAutoLogin));
    } catch (e) {
      setShowLoginErrorToast(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/reservationManagement');
    }
  }, [history, isLoggedIn]);

  useEffect(() => {
    if (error) {
      setShowLoginErrorToast(true);
    }
  }, [error]);

  useEffect(() => {
    const refreshToken = getLocalStorage<string>(REFRESH_TOKEN, '');

    if (refreshToken) {
      try {
        dispatch(refresh(refreshToken));
        history.replace('/reservationManagement');
      } catch (e) {
        console.log('Failed to refresh token. invalid/expired token');
      }
    }
  }, [history, dispatch]);

  const handleAutoLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoLogin(event.target.checked);
  };

  const onLoginErrorToastClose = () => {
    setShowLoginErrorToast(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Snackbar
        open={showLoginErrorToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={1500}
        onClose={onLoginErrorToastClose}
        message="아이디 및 비밀번호를 확인해주세요"
      />

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main' }}
          style={{ margin: '8px', background: 'none', borderRadius: '0' }}
        >
          <img
            src={tennisBoxLogoImg}
            alt="tennisBoxLogoImg"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Avatar>
        <Typography component="h1" variant="h5">
          테니스박스 관리자페이지
        </Typography>

        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="accountId"
            label="아이디"
            name="accountId"
            autoComplete="accountId"
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="비밀번호"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <FormControlLabel
            control={
              <Checkbox checked={isAutoLogin} onChange={handleAutoLoginChange} color="primary" />
            }
            label="자동로그인"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{
              marginTop: '24px',
              padding: '10px',
              color: 'white',
            }}
          >
            로그인
          </Button>

          <Grid container>
            {/* <Grid item xs>
                  <Link href="/search-password" variant="body2">
                    비밀번호 찾기
                  </Link>
                </Grid> */}
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.tennisbox.kr">
        Team GND3
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default LoginPage;
