import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import moment, { Moment } from 'moment';
import { SystemStyleObject } from '@mui/system';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CalendarPicker, LocalizationProvider } from '@mui/lab';
import { Theme } from '@mui/material/styles';
import axios, { AxiosError } from 'axios';
import { RootState } from '../../store';
import Court, { CourtsByClub } from '../../model/Court';
import { ReservationWithUser } from '../../model/Reservation';
import { TimeBlocksWithDate } from '../../model/TimeBlocksWithDate';
import getCourtDailyOperationInfo from '../../api/getCourtDailyOperationInfo';
import {
  getReservationTimeBlockWithPreoccupied,
  getReservationTimeBlocksFromIndex,
} from '../../helper/getReservationTimeBlock';
import { TimeBlock, TimeRange } from '../../model/Time';
import ReservationTimeButton from '../../components/Button/ReservationTimeButton';
import { compareNumberAscending } from '../../helper/compareHelper';
import { extractPhoneNumberFrom } from '../../helper/servicePolicyHelper';
import updateDailyReservation from '../../api/updateDailyReservation';
import { ClubTimePolicy } from '../../model/TimePolicy';
import { showWithDashes } from '../../helper/stringHelper';
import { dateStringToMoment, getWeekCntOfMonth, momentToDateString } from '../../helper/dateHelper';
import { DuplicateReservationTimeException } from '../../model/Exception';
import { LessonWithManagerUser } from '../../model/Lesson';
import { ManagerUser } from '../../model/ManagerUser';
import updateDailyReservationAndLesson from '../../api/updateDailyReservationAndLesson';
import ManagerUserAutoComplete from '../../components/Input/ManagerUserAutoComplete';

interface Props {
  open: boolean;
  toggleModal: () => void;
  selectedReservationWithUser: ReservationWithUser;
  selectedLessonWithManagerUser: LessonWithManagerUser | null;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void; // For Refresh Usage
  globalSelectedDate: Date; // For Refresh Usage
}

const minDate = moment('2020-01-01T00:00:00.000');

export default function DailyReservationDetailModal({
  open,
  toggleModal,
  refreshReservationsAndLessonsOfClub,
  selectedReservationWithUser,
  selectedLessonWithManagerUser,
  globalSelectedDate,
}: Props) {
  const { courtsByClub } = useSelector((s: RootState) => s.ClubCourtReducer);
  const [selectedCourtsByClub] = useState<CourtsByClub>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    courtsByClub.find((it) => it.club.id === selectedReservationWithUser.clubId)!,
  );

  const [selectedCourt, setSelectedCourt] = useState<Court>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedCourtsByClub.courts.find((it) => it.id === selectedReservationWithUser.courtId)!,
  );
  const [isClubSelectDialogOpen, setIsClubSelectDialogOpen] = useState<boolean>(false);
  const [isCourtSelectDialogOpen, setIsCourtSelectDialogOpen] = useState<boolean>(false);

  const [title, setTitle] = useState<string>(selectedReservationWithUser.title);
  const [userName, setUserName] = useState<string>(selectedReservationWithUser.user.name);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>(
    extractPhoneNumberFrom(selectedReservationWithUser.user.phoneNumber),
  );

  const [selectedDate, setSelectedDate] = useState<Moment>(
    dateStringToMoment(selectedReservationWithUser.date),
  );

  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState<boolean>(false);
  const [weekCntOfCalendarMonth, setWeekCntOfCalendarMonth] = useState<number>(5);

  const [timeBlocksWithDate, setTimeBlocksWithDate] = useState<TimeBlocksWithDate>({
    date: '',
    timeBlocks: [],
  });
  const [selectedTimeBlockIndexList, setSelectedTimeBlockIndexList] = useState<number[]>([]);
  const [tempSelectedTimeBlockIndexList, setTempSelectedTimeBlockIndexList] = useState<number[]>(
    [],
  );
  const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [previousCourtTimePolicy, setPreviousCourtTimePolicy] = useState<ClubTimePolicy | null>(
    null,
  );

  const [selectedManagerUser, setSelectedManagerUser] = useState<ManagerUser | null>(
    selectedLessonWithManagerUser === null ? null : selectedLessonWithManagerUser.managerUser,
  );

  useEffect(() => {
    const init = async () => {
      if (isLoading) {
        const dateString = momentToDateString(selectedDate);

        try {
          const reservationTimeInfo = await getCourtDailyOperationInfo(
            selectedReservationWithUser.clubId,
            selectedReservationWithUser.courtId,
            dateString,
          );

          const curCourtTimePolicy = {
            openTimePolicy: reservationTimeInfo.openTimePolicy,
            breakTimePolicy: reservationTimeInfo.breakTimePolicy,
          };

          setPreviousCourtTimePolicy(curCourtTimePolicy);

          const reservationTimeBlocks = getReservationTimeBlockWithPreoccupied(
            reservationTimeInfo,
            dateString,
          );

          if (reservationTimeBlocks.length !== 0) {
            makeOriginalTimeBlocksSelectable(reservationTimeBlocks, true);
          }

          setTimeBlocksWithDate({
            date: dateString,
            timeBlocks: reservationTimeBlocks,
          });
        } catch (e: unknown) {
          console.log(e);
          alert('서버 오류. 죄송합니다.');
        }

        setIsLoading(false);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    selectedDate,
    selectedReservationWithUser.clubId,
    selectedReservationWithUser.courtId,
  ]);

  const handleClose = () => toggleModal();

  const onClubSelectChange = () => {
    // setIsClubSelectDialogOpen(false);
  };

  const onCourtSelectChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const targetCourtId = Number((event.target as HTMLInputElement).value);
      const targetCourt = selectedCourtsByClub.courts.find((it) => it.id === targetCourtId);

      if (targetCourt) {
        setSelectedCourt(targetCourt);

        const dateString = momentToDateString(selectedDate);

        try {
          const reservationTimeInfo = await getCourtDailyOperationInfo(
            selectedCourtsByClub.club.id,
            targetCourtId,
            dateString,
          );

          const curCourtTimePolicy = {
            openTimePolicy: reservationTimeInfo.openTimePolicy,
            breakTimePolicy: reservationTimeInfo.breakTimePolicy,
          };

          const reservationTimeBlocks = getReservationTimeBlockWithPreoccupied(
            reservationTimeInfo,
            dateString,
          );

          // 코트별 시간 정책이 다를 경우, 선택 초기화 (Object 비교하기)
          if (JSON.stringify(previousCourtTimePolicy) !== JSON.stringify(curCourtTimePolicy)) {
            setTempSelectedTimeBlockIndexList([]);
            setSelectedTimeBlockIndexList([]);
            setIsCourtSelectDialogOpen(false);
            setTimeBlocksWithDate({
              date: dateString,
              timeBlocks: reservationTimeBlocks,
            });
            setPreviousCourtTimePolicy(curCourtTimePolicy);
            return;
          }

          // 코트가 변경되었지만, 시간 정책이 동일함
          setPreviousCourtTimePolicy(curCourtTimePolicy);

          if (reservationTimeBlocks.length !== 0) {
            // 변경한 코트가 기존 코트일 경우, 기존 일정 타임블락 선택 가능하도록 설정
            if (selectedReservationWithUser.courtId === targetCourtId) {
              makeOriginalTimeBlocksSelectable(reservationTimeBlocks, false);
            }

            // 코트를 변경했을 때, 선택했던 타임블락을 유지할 수 없다면 초기화
            const isTimePreOccupiedByOtherReservations = selectedTimeBlockIndexList.find(
              (it) => reservationTimeBlocks[it].disabled,
            );
            if (isTimePreOccupiedByOtherReservations) {
              setTempSelectedTimeBlockIndexList([]);
              setSelectedTimeBlockIndexList([]);
              setIsCourtSelectDialogOpen(false);
              setTimeBlocksWithDate({
                date: dateString,
                timeBlocks: reservationTimeBlocks,
              });
              return;
            }
          }

          setTimeBlocksWithDate({
            date: dateString,
            timeBlocks: reservationTimeBlocks,
          });
        } catch (e: unknown) {
          console.log(e);
          alert('서버 오류. 죄송합니다.');
        }
      }

      setIsCourtSelectDialogOpen(false);
    } catch (e) {
      console.log(e);
      alert('네트워크 오류 - 코트를 다시 선택해주세요');
    }
  };

  const makeOriginalTimeBlocksSelectable = (
    reservationTimeBlocks: TimeBlock[],
    shouldSetBlockSelected: boolean,
  ) => {
    let startBlockIdx = -1;
    let endBlockIdx = -1;
    reservationTimeBlocks.forEach((it, idx) => {
      if (it.startTime === selectedReservationWithUser.reservationTimeRange.startTime) {
        startBlockIdx = idx;
      }

      if (it.endTime === selectedReservationWithUser.reservationTimeRange.endTime) {
        endBlockIdx = idx;
      }
    });

    const timeBlockIndexList = [];
    for (let i = startBlockIdx; i >= 0 && i <= endBlockIdx; i += 1) {
      // eslint-disable-next-line no-param-reassign
      reservationTimeBlocks[i].disabled = false; // 현재 일정의 시간은 선택 가능해야한다.
      if (shouldSetBlockSelected) {
        timeBlockIndexList.push(i);
      }
    }

    if (shouldSetBlockSelected) {
      // 현재 일정의 Time Block 이 미리 선택되어 있도록 설정
      setTempSelectedTimeBlockIndexList(timeBlockIndexList);
      setSelectedTimeBlockIndexList(timeBlockIndexList);
    }
  };

  const handleDateSelectedEvent = async (date: Moment | null) => {
    if (date) {
      setSelectedDate(date);

      const dateString = momentToDateString(date);

      try {
        const reservationTimeInfo = await getCourtDailyOperationInfo(
          selectedCourtsByClub.club.id,
          selectedCourt.id,
          dateString,
        );

        const curCourtTimePolicy = {
          openTimePolicy: reservationTimeInfo.openTimePolicy,
          breakTimePolicy: reservationTimeInfo.breakTimePolicy,
        };

        const reservationTimeBlocks = getReservationTimeBlockWithPreoccupied(
          reservationTimeInfo,
          dateString,
        );

        // 날짜별 시간 정책이 다를 경우, 선택 초기화 (Object 비교하기)
        if (JSON.stringify(previousCourtTimePolicy) !== JSON.stringify(curCourtTimePolicy)) {
          setTempSelectedTimeBlockIndexList([]);
          setSelectedTimeBlockIndexList([]);
          setIsCalendarPickerOpen(false);
          setTimeBlocksWithDate({
            date: dateString,
            timeBlocks: reservationTimeBlocks,
          });
          setPreviousCourtTimePolicy(curCourtTimePolicy);
          return;
        }

        // 날짜가 달라졌지만 시간 정책은 동일함.
        setPreviousCourtTimePolicy(curCourtTimePolicy);

        if (reservationTimeBlocks.length !== 0) {
          if (selectedReservationWithUser.courtId === selectedCourt.id) {
            // 변경한 날짜가 기존 일정의 날짜와 동일할 경우, 기존 일정 타임블락 선택 가능하도록 설정
            makeOriginalTimeBlocksSelectable(reservationTimeBlocks, false);
          }

          // 날짜를 변경했을 때, 선택했던 타임블락을 유지할 수 없다면 초기화
          const isTimePreOccupiedByOtherReservations = selectedTimeBlockIndexList.find(
            (it) => reservationTimeBlocks[it].disabled,
          );
          if (isTimePreOccupiedByOtherReservations) {
            setTempSelectedTimeBlockIndexList([]);
            setSelectedTimeBlockIndexList([]);
            setIsCalendarPickerOpen(false);
            setTimeBlocksWithDate({
              date: dateString,
              timeBlocks: reservationTimeBlocks,
            });
            return;
          }
        }

        setTimeBlocksWithDate({
          date: dateString,
          timeBlocks: reservationTimeBlocks,
        });
      } catch (e) {
        console.log(e);
        alert('네트워크 오류 - 코트를 다시 선택해주세요');
      }
    }

    setIsCalendarPickerOpen(false);
  };

  const isOriginalReservationTimeRangeIsSameWith = (selectedTimeBlocks: TimeRange[]): boolean => {
    return (
      selectedReservationWithUser.reservationTimeRange.startTime ===
        selectedTimeBlocks[0].startTime &&
      selectedReservationWithUser.reservationTimeRange.endTime ===
        selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime
    );
  };

  const requestUpdateDailyReservation = async () => {
    if (!areReservationRelatedFieldsFilled()) {
      return;
    }

    const requestBody = makeUpdateDailyReservationRequestBody();

    try {
      await updateDailyReservation(selectedReservationWithUser.reservationId, requestBody);

      await refreshReservationsAndLessonsOfClub(
        selectedCourtsByClub.club.id,
        moment(globalSelectedDate),
      );
      toggleModal();
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        alertDuplicateReservationTime(e);
        return;
      }

      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  const onUpdateButtonClick = async () => {
    if (isLesson()) {
      await requestUpdateDailyReservationAndLesson();
    } else {
      await requestUpdateDailyReservation();
    }
  };

  const areReservationRelatedFieldsFilled = (): boolean => {
    if (title.length === 0) {
      alert('일정 제목을 입력해주세요');
      return false;
    }

    if (userName.length === 0) {
      alert('예약자 성함을 입력해주세요');
      return false;
    }

    if (userPhoneNumber.length !== 11) {
      alert('핸드폰 번호 11자리를 모두 입력해주세요');
      return false;
    }

    return true;
  };

  const makeUpdateDailyReservationRequestBody = () => {
    const dateString = momentToDateString(selectedDate);
    let date = selectedReservationWithUser.date !== dateString ? dateString : null;
    let courtIdToChange =
      selectedReservationWithUser.courtId !== selectedCourt.id ? selectedCourt.id : null;

    // 기존 일정과 다른 경우, 변경할 시간 데이터 설정
    let reservationTimeRange = null;

    if (selectedTimeBlockIndexList.length !== 0) {
      const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
        timeBlocksWithDate.timeBlocks,
        selectedTimeBlockIndexList,
      );

      if (
        selectedReservationWithUser.date !== dateString ||
        !isOriginalReservationTimeRangeIsSameWith(selectedTimeBlocks)
      ) {
        reservationTimeRange = [
          {
            startTime: selectedTimeBlocks[0].startTime,
            endTime: selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime,
          },
        ];
      }
    }

    if (reservationTimeRange == null) {
      // UX 상 코트와 날짜는 선택안할 수 없지만, 시간은 선택 안 할 수 있다.
      // 따라서, 시간이 선택되지 않을 때, 날짜와 코트가 이동되는 경우를 허용하지 않는다.
      date = null;
      courtIdToChange = null;
    }

    return {
      clubId: selectedReservationWithUser.clubId,
      courtIdToChange,
      date,
      reservationTimeRangeList: reservationTimeRange,
      title: selectedReservationWithUser.title !== title ? title : null,
      userName: selectedReservationWithUser.user.name !== userName ? userName : null,
      userPhoneNumber:
        selectedReservationWithUser.user.phoneNumber !== userPhoneNumber ? userPhoneNumber : null,
    };
  };

  const requestUpdateDailyReservationAndLesson = async () => {
    if (selectedManagerUser === null) {
      alert('레슨 담당자를 설정해주세요');
      return;
    }

    if (!areReservationRelatedFieldsFilled()) {
      return;
    }

    const requestBody = {
      ...makeUpdateDailyReservationRequestBody(),
      managerUserId: selectedManagerUser !== null ? selectedManagerUser.id : null,
    };

    try {
      await updateDailyReservationAndLesson(selectedReservationWithUser.reservationId, requestBody);

      await refreshReservationsAndLessonsOfClub(selectedCourtsByClub.club.id, selectedDate);
      toggleModal();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        alertDuplicateReservationTime(e);
        return;
      }
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  const onEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleDateButtonClick = () => {
    setIsCalendarPickerOpen((prevState) => !prevState);
  };

  const onTimeSelect = (index: number) => () => {
    setTempSelectedTimeBlockIndexList((prevList) => {
      if (prevList.includes(index)) {
        const indexOfSelectedIndex = prevList.findIndex((listItem) => listItem === index);
        if (indexOfSelectedIndex === 0) {
          return prevList.slice(1);
        }
        if (indexOfSelectedIndex === prevList.length - 1) {
          return prevList.slice(0, prevList.length - 1);
        }
        return [...prevList];
      }
      if (prevList.length >= 1) {
        if (
          Math.abs(prevList[0] - index) === 1 ||
          Math.abs(prevList[prevList.length - 1] - index) === 1
        ) {
          const nextList = [...prevList, index];
          nextList.sort(compareNumberAscending);
          return nextList;
        }
      }

      return [index];
    });
  };

  const getTimeSelectButtonContent = (): string => {
    if (isLoading) {
      return '불러오는 중';
    }

    if (selectedTimeBlockIndexList.length === 0) {
      return '선택 없음';
    }

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocksWithDate.timeBlocks,
      selectedTimeBlockIndexList,
    );

    return `${selectedTimeBlocks[0].startTime}-${
      selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime
    }`;
  };

  const handleTimeButtonClick = () => {
    setIsTimePickerOpen((prevState) => !prevState);
  };

  const handleTimeBlockCancelButtonClick = () => {
    setIsTimePickerOpen(false);
    setTempSelectedTimeBlockIndexList(selectedTimeBlockIndexList);
  };

  const handleTimeBlockConfirmButtonClick = () => {
    setSelectedTimeBlockIndexList(tempSelectedTimeBlockIndexList);
    setIsTimePickerOpen(false);
  };

  const handleTitleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleUserNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleUserPhoneNumberTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b -]{0,11}$/;
    if (regex.test(event.target.value)) {
      setUserPhoneNumber(event.target.value);
    }
  };

  const handleCalendarMonthChange = (firstDayOfMonth: moment.Moment) => {
    setWeekCntOfCalendarMonth(getWeekCntOfMonth(firstDayOfMonth));
  };

  useEffect(() => {
    if (isTimePickerOpen) {
      if (selectedTimeBlockIndexList.length !== 0) {
        scrollToSelectedTimeBlocks();
      } else {
        scrollToNextTimeBlock();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimePickerOpen]);

  const scrollToSelectedTimeBlocks = () => {
    const selectedTimeBlock =
      timeBlocksWithDate.timeBlocks[
        selectedTimeBlockIndexList[Math.ceil((selectedTimeBlockIndexList.length - 1) / 2)]
      ];

    const timeBlockEl = document.getElementById(
      `reservation_time_${timeBlocksWithDate.date}_${selectedTimeBlock.startTime}`,
    );

    if (timeBlockEl) {
      timeBlockEl.scrollIntoView({ inline: 'center' });
    }
  };

  const scrollToNextTimeBlock = () => {
    const curMoment = moment();
    let curHour = curMoment.hour();
    let curMinute = curMoment.minute();
    if (curMinute > 30) {
      curHour += 1;
      curMinute = 0;
    } else {
      curMinute = 30;
    }

    const curHourInString = curHour < 10 ? `0${curHour}` : curHour;
    const curMinuteInString = curMinute < 10 ? `0${curMinute}` : curMinute;

    const timeBlockEl = document.getElementById(
      `reservation_time_${timeBlocksWithDate.date}_${curHourInString}:${curMinuteInString}`,
    );

    if (timeBlockEl) {
      timeBlockEl.scrollIntoView({ inline: 'center' });
    }
  };

  const alertDuplicateReservationTime = (e: AxiosError) => {
    // Note: e.response.data.message 값이 존재하지 않는 경우에는 날짜를 뭉뚱그려서 표현
    if (!e.response?.data?.message) {
      alert('해당 날짜에 이미 일정이 존재합니다\n확인해주세요🤔');
      return;
    }

    const duplicateReservationTime: DuplicateReservationTimeException = JSON.parse(
      e.response.data.message,
    );
    const duplicateDate = dateStringToMoment(duplicateReservationTime.date);
    let alertMessage = `${duplicateDate.month()}월 ${duplicateDate.days()}일 ${
      duplicateReservationTime.startTime
    }에 이미 일정이 존재합니다.\n확인해주세요🤔`;
    if (moment().year() !== duplicateDate.year()) {
      alertMessage = `${duplicateDate.year()}년 ${alertMessage}`;
    }
    alert(alertMessage);
  };

  const isLesson = () => selectedLessonWithManagerUser !== null;

  const handleSelectedManagerUser = (managerUser: ManagerUser | null) =>
    setSelectedManagerUser(managerUser);

  const showManagerUserField = (): JSX.Element => {
    if (!isEditMode) {
      return (
        <Box
          sx={{
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box sx={{ width: '50%' }}>레슨 담당자</Box>

          <Box sx={{ position: 'absolute', right: 0 }}>
            <Box sx={buttonLikeBoxStyle}>
              <img
                width="20"
                src={`${selectedLessonWithManagerUser?.managerUser.profilePhotoPath}`}
                alt=""
              />
              <Box sx={{ ml: '10px' }}>{selectedLessonWithManagerUser?.managerUser.name}</Box>
            </Box>
          </Box>
        </Box>
      );
    }

    if (selectedManagerUser) {
      return (
        <Box
          sx={{
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box sx={{ width: '50%' }}>레슨 담당자</Box>

          <Box sx={{ position: 'absolute', right: 0 }}>
            <Button onClick={() => setSelectedManagerUser(null)} sx={buttonStyle}>
              <img width="20" src={`${selectedManagerUser.profilePhotoPath}`} alt="프로필사진" />
              <Box sx={{ ml: '10px' }}>{selectedManagerUser.name}</Box>
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <ManagerUserAutoComplete
        managerUserList={selectedCourtsByClub.managerUserList}
        handleSelectedManagerUser={handleSelectedManagerUser}
      />
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: '30px' } }}
      >
        <Box sx={{ width: 400, padding: '27px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>일일 일정</Box>
          </Box>
          <Box style={{ marginTop: '32px' }}>
            {/* 일정 제목 */}
            {isEditMode ? (
              <TextField
                required
                id="standard-required"
                label="일정 제목"
                onChange={handleTitleTextChange}
                value={title}
                variant="standard"
                fullWidth
                sx={{ fontSize: '20px' }}
              />
            ) : (
              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ width: '50%' }}>제목</Box>
                <Box sx={{ width: '50%', textAlign: 'end' }}>
                  {selectedReservationWithUser.title}
                </Box>
              </Box>
            )}
            <Box sx={{ marginTop: '20px', flexDirection: 'column' }}>
              <Box sx={{ position: 'relative', display: ' flex' }}>
                {/* 클럽 버튼 */}
                {isEditMode ? (
                  <Button disabled sx={{ ...buttonStyle, mr: '15px' }}>
                    {selectedCourtsByClub.club.name}
                  </Button>
                ) : (
                  <Box
                    sx={{
                      ...buttonLikeBoxStyle,
                      mr: '15px',
                    }}
                  >
                    {selectedCourtsByClub.club.name}
                  </Box>
                )}

                {/* 코트 버튼 */}
                {isEditMode ? (
                  <Button
                    onClick={() => setIsCourtSelectDialogOpen(true)}
                    sx={isCourtSelectDialogOpen ? selectedButtonStyle : buttonStyle}
                  >
                    {selectedCourt.name}
                  </Button>
                ) : (
                  <Box sx={buttonLikeBoxStyle}>{selectedCourt.name}</Box>
                )}
              </Box>

              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Box sx={{ width: '50%' }}>날짜</Box>

                <Box sx={{ position: 'absolute', right: 0 }}>
                  {isEditMode ? (
                    <Button
                      onClick={handleDateButtonClick}
                      sx={isCalendarPickerOpen ? selectedButtonStyle : buttonStyle}
                    >
                      {selectedDate.locale('ko').format('yyyy. MM. DD (dd)')}
                    </Button>
                  ) : (
                    <Box
                      sx={{
                        ...buttonLikeBoxStyle,
                      }}
                    >
                      {selectedDate.locale('ko').format('yyyy. MM. DD (dd)')}
                    </Box>
                  )}
                </Box>
              </Box>

              {isCalendarPickerOpen && (
                <Box
                  sx={{
                    marginTop: '19px',
                  }}
                >
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <CalendarPicker
                      minDate={minDate}
                      date={selectedDate}
                      onChange={handleDateSelectedEvent}
                      onMonthChange={handleCalendarMonthChange}
                      views={['day']}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              <Box sx={getBoxStyleUnderCalendar(isCalendarPickerOpen, weekCntOfCalendarMonth)}>
                <Box style={{ width: '50%' }}>시간</Box>
                <Box style={{ position: 'absolute', right: 0 }}>
                  {isEditMode ? (
                    <Button
                      onClick={handleTimeButtonClick}
                      sx={isTimePickerOpen ? selectedButtonStyle : buttonStyle}
                    >
                      {getTimeSelectButtonContent()}
                    </Button>
                  ) : (
                    <Box
                      sx={{
                        ...buttonLikeBoxStyle,
                      }}
                    >
                      {getTimeSelectButtonContent()}
                    </Box>
                  )}
                </Box>
              </Box>

              {/* 시간 미선택 경고 노티 */}
              {!isLoading &&
                !isTimePickerOpen &&
                isEditMode &&
                selectedTimeBlockIndexList.length === 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '18px' }}>
                    <Box sx={{ color: '#FF6392', fontSize: '0.7rem', fontWeight: 700 }}>
                      🚨 시간 미선택 시, 코트와 날짜는 변경 적용되지 않습니다.
                    </Box>
                  </Box>
                )}

              {isTimePickerOpen && (
                <Box sx={{ marginTop: '20px' }}>
                  <Box sx={{ fontSize: '0.7em', color: '#FF6392' }}>
                    💡 연속되지 않은 시간 선택 시, 이전 선택된 시간들은 선택 해제됩니다.
                  </Box>
                  <Box sx={{ marginTop: '10px', display: 'flex', overflowX: 'scroll' }}>
                    {timeBlocksWithDate.timeBlocks.map((timeBlock: TimeBlock, index: number) => {
                      const { startTime, disabled } = timeBlock;
                      return (
                        <ReservationTimeButton
                          key={`reservation_time_${timeBlocksWithDate.date}_${startTime}`}
                          id={`reservation_time_${timeBlocksWithDate.date}_${startTime}`}
                          timeText={`${startTime}`}
                          disabled={disabled}
                          selected={tempSelectedTimeBlockIndexList.includes(index)}
                          onPress={onTimeSelect(index)}
                        />
                      );
                    })}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleTimeBlockCancelButtonClick}>취소</Button>
                    <Button onClick={handleTimeBlockConfirmButtonClick}>확인</Button>
                  </Box>
                </Box>
              )}

              {isEditMode ? (
                <Box sx={isTimePickerOpen ? {} : { marginTop: '25px' }}>
                  <TextField
                    required
                    id="standard-required"
                    label="예약자 성함"
                    variant="standard"
                    onChange={handleUserNameTextChange}
                    value={userName}
                    fullWidth
                  />
                </Box>
              ) : (
                <Box sx={{ marginTop: '25px' }}>
                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>예약자</Box>
                    <Box sx={{ width: '50%', textAlign: 'end' }}>
                      {selectedReservationWithUser.user.name}
                    </Box>
                  </Box>
                </Box>
              )}

              <Box sx={{ marginTop: '25px' }}>
                {isEditMode ? (
                  <TextField
                    required
                    id="standard-required"
                    label="예약자 핸드폰 번호"
                    variant="standard"
                    onChange={handleUserPhoneNumberTextChange}
                    value={userPhoneNumber}
                    InputProps={{ type: 'text' }}
                    placeholder={"'-'를 제외한 숫자 11자리"}
                    helperText={"'-'를 제외한 숫자 11자리"}
                    fullWidth
                  />
                ) : (
                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>예약자 핸드폰 번호</Box>
                    <Box sx={{ width: '50%', textAlign: 'end' }}>
                      {showWithDashes(
                        extractPhoneNumberFrom(selectedReservationWithUser.user.phoneNumber),
                      )}
                    </Box>
                  </Box>
                )}
              </Box>

              {isLesson() && showManagerUserField()}

              <Box style={{ flexDirection: 'row', marginTop: '30px', position: 'relative' }}>
                {isEditMode ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#F2F3F5',
                        color: '#000000',
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      취소
                    </Button>
                    <Button
                      variant="contained"
                      onClick={onUpdateButtonClick}
                      sx={{
                        backgroundColor: '#38B882',
                        color: '#FFFFFF',
                        position: 'absolute',
                        right: 0,
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      적용
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#F2F3F5',
                        color: '#000000',
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      닫기
                    </Button>
                    <Button
                      variant="contained"
                      onClick={onEditButtonClick}
                      sx={{
                        backgroundColor: '#FF6392',
                        color: '#FFFFFF',
                        position: 'absolute',
                        right: 0,
                        width: '47%',
                        boxShadow: 'none',
                        padding: '12px 16px',
                        fontSize: '0.855rem',
                        fontWeight: 'bold',
                      }}
                    >
                      수정
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {isClubSelectDialogOpen && (
        <Dialog onClose={() => setIsClubSelectDialogOpen(false)} open={isClubSelectDialogOpen}>
          <DialogTitle>구장 선택</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup name="controlled-radio-buttons-group" onChange={onClubSelectChange}>
                {courtsByClub.map((it) => (
                  <FormControlLabel
                    key={it.club.id}
                    value={it.club.id}
                    control={<Radio />}
                    label={it.club.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
      {isCourtSelectDialogOpen && (
        <Dialog onClose={() => setIsCourtSelectDialogOpen(false)} open={isCourtSelectDialogOpen}>
          <DialogTitle>코트 선택</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup name="controlled-radio-buttons-group" onChange={onCourtSelectChange}>
                {selectedCourtsByClub.courts.map((court) => (
                  <FormControlLabel
                    key={court.id}
                    value={court.id}
                    control={<Radio />}
                    label={court.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

const buttonStyle: SystemStyleObject = {
  backgroundColor: '#F2F3F5',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '15px',
  width: '165px',
  height: '44px',
  textAlign: 'center',
  color: '#000000',
};

const selectedButtonStyle: SystemStyleObject = {
  ...buttonStyle,
  backgroundColor: '#FFFFFF',
  color: '#38B882',
  border: '0.7px solid #38B882',
};

const buttonLikeBoxStyle: SystemStyleObject = {
  ...buttonStyle,
  backgroundColor: 'white',
  color: 'black',
  border: 'solid 1px #DDDDDD',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
  letterSpacing: '0.02857em',
  minWidth: '64px',
  padding: '6px 8px',
  borderRadius: '4px',
  display: 'flex',
};

const getBoxStyleUnderCalendar = (
  isCalendarOpen: boolean,
  weekCntOfCalendarMonth: number,
): SystemStyleObject<Theme> => {
  if (isCalendarOpen) {
    if (weekCntOfCalendarMonth === 6) {
      return {
        marginTop: '-20px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      };
    }

    return {
      marginTop: '-45px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    };
  }

  return {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  };
};
