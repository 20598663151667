import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Moment } from 'moment';
import { SystemStyleObject } from '@mui/system';
import { useSelector } from 'react-redux';
import { dateStringToMoment } from '../../helper/dateHelper';
import { ReservationWithUser } from '../../model/Reservation';
import { LessonWithManagerUser } from '../../model/Lesson';
import cancelReservation from '../../api/cancelReservation';
import { showWithDashes } from '../../helper/stringHelper';
import { extractPhoneNumberFrom } from '../../helper/servicePolicyHelper';
import { RootState } from '../../store';

interface Props {
  open: boolean;
  toggleModal: () => void;
  selectedReservationWithUser: ReservationWithUser;
  selectedLessonWithManagerUser: LessonWithManagerUser | null;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void; // For Refresh Usage
}

export default function CancelReservationModal({
  open,
  toggleModal,
  selectedReservationWithUser,
  selectedLessonWithManagerUser,
  refreshReservationsAndLessonsOfClub,
}: Props) {
  const { courtsByClub } = useSelector((s: RootState) => s.ClubCourtReducer);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedCourtsByClub = courtsByClub.find(
    (it) => it.club.id === selectedReservationWithUser.clubId,
  )!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedCourt = selectedCourtsByClub.courts.find(
    (it) => it.id === selectedReservationWithUser.courtId,
  )!;
  const selectedDate = dateStringToMoment(selectedReservationWithUser.date);

  const handleClose = () => toggleModal();
  const isLesson = () => selectedLessonWithManagerUser !== null;

  const onDeleteButtonClick = async () => {
    try {
      await cancelReservation(selectedReservationWithUser.reservationId);

      await refreshReservationsAndLessonsOfClub(
        selectedReservationWithUser.clubId,
        dateStringToMoment(selectedReservationWithUser.date),
      );
      toggleModal();
    } catch (e) {
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: '30px' } }}
      >
        <Box sx={{ width: 400, padding: '27px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>일정 삭제</Box>
          </Box>
          <Box style={{ marginTop: '32px' }}>
            {/* 일정 제목 */}

            <Box
              sx={{
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box sx={{ width: '50%' }}>제목</Box>
              <Box sx={{ width: '50%', textAlign: 'end' }}>{selectedReservationWithUser.title}</Box>
            </Box>
            <Box sx={{ marginTop: '20px', flexDirection: 'column' }}>
              <Box sx={{ position: 'relative', display: ' flex' }}>
                {/* 클럽 버튼 */}

                <Box
                  sx={{
                    ...buttonLikeBoxStyle,
                    mr: '15px',
                  }}
                >
                  {selectedCourtsByClub.club.name}
                </Box>

                <Box sx={buttonLikeBoxStyle}>{selectedCourt.name}</Box>
              </Box>

              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Box sx={{ width: '50%' }}>날짜</Box>

                <Box sx={{ position: 'absolute', right: 0 }}>
                  <Box
                    sx={{
                      ...buttonLikeBoxStyle,
                    }}
                  >
                    {selectedDate.locale('ko').format('yyyy. MM. DD (dd)')}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Box style={{ width: '50%' }}>시간</Box>
                <Box style={{ position: 'absolute', right: 0 }}>
                  <Box
                    sx={{
                      ...buttonLikeBoxStyle,
                    }}
                  >
                    {`${selectedReservationWithUser.reservationTimeRange.startTime}-${selectedReservationWithUser.reservationTimeRange.endTime}`}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ marginTop: '25px' }}>
                <Box
                  sx={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ width: '50%' }}>예약자</Box>
                  <Box sx={{ width: '50%', textAlign: 'end' }}>
                    {selectedReservationWithUser.user.name}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ marginTop: '25px' }}>
                <Box
                  sx={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ width: '50%' }}>예약자 핸드폰 번호</Box>
                  <Box sx={{ width: '50%', textAlign: 'end' }}>
                    {showWithDashes(
                      extractPhoneNumberFrom(selectedReservationWithUser.user.phoneNumber),
                    )}
                  </Box>
                </Box>
              </Box>

              {isLesson() && (
                <Box
                  sx={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ width: '50%' }}>레슨 담당자</Box>

                  <Box sx={{ position: 'absolute', right: 0 }}>
                    <Box sx={buttonLikeBoxStyle}>
                      <img
                        width="20"
                        src={`${selectedLessonWithManagerUser?.managerUser.profilePhotoPath}`}
                        alt=""
                      />
                      <Box sx={{ ml: '10px' }}>
                        {selectedLessonWithManagerUser?.managerUser.name}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              <Box sx={{ marginTop: '28px', fontWeight: 'bold' }}>일정을 삭제하시겠습니까?</Box>
              <Box style={{ flexDirection: 'row', marginTop: '32px', position: 'relative' }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    backgroundColor: '#F2F3F5',
                    color: '#000000',
                    width: '47%',
                    boxShadow: 'none',
                    padding: '12px 16px',
                    fontSize: '0.855rem',
                    fontWeight: 'bold',
                  }}
                >
                  닫기
                </Button>
                <Button
                  variant="contained"
                  onClick={onDeleteButtonClick}
                  color="warning"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    width: '47%',
                    boxShadow: 'none',
                    padding: '12px 16px',
                    fontSize: '0.855rem',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  삭제
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

const buttonStyle: SystemStyleObject = {
  backgroundColor: '#F2F3F5',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '15px',
  width: '165px',
  height: '44px',
  textAlign: 'center',
  color: '#000000',
};

const buttonLikeBoxStyle: SystemStyleObject = {
  ...buttonStyle,
  backgroundColor: 'white',
  color: 'black',
  border: 'solid 1px #DDDDDD',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
  letterSpacing: '0.02857em',
  minWidth: '64px',
  padding: '6px 8px',
  borderRadius: '4px',
  display: 'flex',
};
