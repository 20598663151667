import { AxiosResponse } from 'axios';
import requestPost from './helper/requestPost';

interface RequestBody {
  refreshToken: string;
}

interface ResponseBody {
  accessToken: string;
  refreshToken: string;
}

const postRefresh = (refreshToken: string): Promise<AxiosResponse<ResponseBody>> => {
  const endpoint = '/admin/refresh';

  return requestPost<RequestBody, ResponseBody>(endpoint, {
    refreshToken,
  });
};
export default postRefresh;
