import { AxiosResponse } from 'axios';
import requestPatch from './helper/requestPatch';

interface RequestBody {
  managerUserId: number;
}

const assignLessonToReservation = (
  reservationId: number,
  requestBody: RequestBody,
): Promise<AxiosResponse<void>> => {
  const endpoint = `/admin/reservations/${reservationId}/lessonify`;

  return requestPatch<RequestBody, void>(endpoint, {
    ...requestBody,
  });
};

export default assignLessonToReservation;
