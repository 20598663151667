import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CourtsByClub } from '../../model/Court';

interface Props {
  courtByClubList: CourtsByClub[];
  selectedClubIndex: number;
  onChange: (event: SelectChangeEvent) => void;
}

const ClubSelect = ({ courtByClubList, selectedClubIndex, onChange }: Props) => {
  return (
    <FormControl fullWidth>
      <Select
        value={`${selectedClubIndex}`}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        {courtByClubList.map((courtByClub, index) => (
          <MenuItem key={courtByClub.club.id} value={index}>
            {courtByClub.club.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClubSelect;
