import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Moment } from 'moment';
import { SystemStyleObject } from '@mui/system';
import { useSelector } from 'react-redux';
import { dateStringToMoment } from '../../helper/dateHelper';
import ManagerUserAutoComplete from '../../components/Input/ManagerUserAutoComplete';
import { ManagerUser } from '../../model/ManagerUser';
import assignLessonToReservation from '../../api/assignLessonToReservation';
import { ReservationWithUser } from '../../model/Reservation';
import { RootState } from '../../store';

interface Props {
  open: boolean;
  toggleModal: () => void;
  selectedReservationWithUser: ReservationWithUser;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void; // For Refresh Usage
}

export default function AssignLessonModal({
  open,
  toggleModal,
  selectedReservationWithUser,
  refreshReservationsAndLessonsOfClub,
}: Props) {
  const { courtsByClub } = useSelector((s: RootState) => s.ClubCourtReducer);
  const [selectedManagerUser, setSelectedManagerUser] = useState<ManagerUser | null>(null);

  const handleClose = () => toggleModal();

  const handleSelectedManagerUser = (managerUser: ManagerUser | null) =>
    setSelectedManagerUser(managerUser);

  const onApplyButtonClick = async () => {
    if (selectedManagerUser === null) {
      alert('레슨 담당자를 선택해주세요.');
      return;
    }

    try {
      await assignLessonToReservation(selectedReservationWithUser.reservationId, {
        managerUserId: selectedManagerUser.id,
      });

      await refreshReservationsAndLessonsOfClub(
        selectedReservationWithUser.clubId,
        dateStringToMoment(selectedReservationWithUser.date),
      );
      toggleModal();
    } catch (e) {
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: '30px' } }}
      >
        <Box sx={{ width: 400, padding: '27px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>레슨 할당</Box>
          </Box>
          <Box style={{ marginTop: '32px' }}>
            {selectedManagerUser === null ? (
              <ManagerUserAutoComplete
                managerUserList={courtsByClub[0].managerUserList}
                handleSelectedManagerUser={handleSelectedManagerUser}
              />
            ) : (
              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Box sx={{ width: '50%' }}>레슨 담당자</Box>

                <Box sx={{ position: 'absolute', right: 0 }}>
                  <Button onClick={() => setSelectedManagerUser(null)} sx={buttonStyle}>
                    <img
                      width="20"
                      src={`${selectedManagerUser.profilePhotoPath}`}
                      alt="프로필사진"
                    />
                    <Box sx={{ ml: '10px' }}>{selectedManagerUser.name}</Box>
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box style={{ flexDirection: 'row', marginTop: '55px', position: 'relative' }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: '#F2F3F5',
                color: '#000000',
                width: '47%',
                boxShadow: 'none',
                padding: '12px 16px',
                fontSize: '0.855rem',
                fontWeight: 'bold',
              }}
            >
              취소
            </Button>
            <Button
              variant="contained"
              onClick={onApplyButtonClick}
              color="primary"
              sx={{
                position: 'absolute',
                right: 0,
                width: '47%',
                boxShadow: 'none',
                padding: '12px 16px',
                fontSize: '0.855rem',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              적용
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

const buttonStyle: SystemStyleObject = {
  backgroundColor: '#F2F3F5',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '15px',
  width: '165px',
  height: '44px',
  textAlign: 'center',
  color: '#000000',
};
