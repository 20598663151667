import { AxiosResponse } from 'axios';
import { TimeRange } from '../model/Time';
import requestPatch from './helper/requestPatch';

interface RequestBody {
  clubId: number;
  courtIdToChange: number | null;
  date: string | null;
  reservationTimeRangeList: TimeRange[] | null;
  title: string | null;
  userName: string | null;
  userPhoneNumber: string | null;
}

const updateDailyReservation = (
  reservationId: number,
  requestBody: RequestBody,
): Promise<AxiosResponse<void>> => {
  const endpoint = `/admin/reservations/${reservationId}`;

  return requestPatch<RequestBody, void>(endpoint, {
    ...requestBody,
  });
};

export default updateDailyReservation;
