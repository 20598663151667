import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import 'moment/locale/ko';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SxProps } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CalendarPicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import RepeatIcon from '@mui/icons-material/Repeat';
import ReservationCalendar from '../../components/Calendar/ReservationCalendar';
import getReservationsOfClubOnDateBetween from '../../api/getReservationsOfClubOnDateBetween';
import { RootState } from '../../store';
import Card from '../../components/Card/Card';
import ListDialog from '../../components/Dialog/ListDialog';
import Court from '../../model/Court';
import Club from '../../model/Club';
import { ReservationWithUser } from '../../model/Reservation';
import CreateDailyReservationModal from './CreateDailyReservationModal';
import CreatePeriodicReservationModal from './CreatePeriodicReservationModal';
import CreatePeriodicReservationBlockModal from './CreatePeriodicReservationBlockModal';
import { ReactComponent as RepeatBlock } from '../../assets/icons/RepeatBlock.svg';
import CreateDailyReservationBlockModal from './CreateDailyReservationBlockModal';
import getLessonsOfClubOnDateBetween from '../../api/getLessonsOfClubOnDateBetween';
import { LessonWithManagerUser } from '../../model/Lesson';

const minDate = moment('2020-01-01T00:00:00.000');

const ReservationManagementPage = (): JSX.Element => {
  const { courtsByClub, isFetching: isFetchingClubCourts } = useSelector(
    (s: RootState) => s.ClubCourtReducer,
  );

  const [isCreateDailyReservationModalOpen, setIsCreateDailyReservationModalOpen] =
    useState<boolean>(false);
  const [isCreatePeriodicReservationModalOpen, setIsCreatePeriodicReservationModalOpen] =
    useState<boolean>(false);
  const [isCreateDailyReservationBlockModalOpen, setIsCreateDailyReservationBlockModalOpen] =
    useState<boolean>(false);
  const [isCreatePeriodicReservationBlockModalOpen, setIsCreatePeriodicReservationBlockModalOpen] =
    useState<boolean>(false);
  const [openListDialog, setOpenListDialog] = useState(false);
  const [openCalendarDialog, setOpenCalendarDialog] = useState(false);
  const [selectedClub, setSelectedClub] = useState<Club | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedReservationsWithUser, setSelectedReservationsWithUser] = useState<
    ReservationWithUser[]
  >([]);
  const [lessonsWithManagerUser, setLessonsWithManagerUser] = useState<LessonWithManagerUser[]>([]);

  const initiallyRenderedRef = useRef(false);

  const selectedCourts = useMemo((): Court[] => {
    if (!selectedClub) {
      return [];
    }
    const currentClub = courtsByClub.find((it) => it.club.id === selectedClub.id);
    if (!currentClub) {
      return [];
    }
    return currentClub.courts;
  }, [courtsByClub, selectedClub]);

  const fetchReservationsOfClub = useCallback(async (clubId: number, date: Moment) => {
    try {
      const response = await getReservationsOfClubOnDateBetween(
        clubId,
        date.toISOString(true),
        date.toISOString(true),
      );

      const reservationsOfAllCourts = response.flatMap((reservationsPerDate) =>
        reservationsPerDate.reservationPerDateList.flatMap(
          (reservationsPerCourt) => reservationsPerCourt.reservations,
        ),
      );

      setSelectedReservationsWithUser(reservationsOfAllCourts);
    } catch (e) {
      alert('일정을 조회하는데 실패하였습니다.');
      console.log(e);
    }
  }, []);

  const fetchLessonsOfClub = useCallback(async (clubId: number, date: Moment) => {
    try {
      const fetchedLessons = await getLessonsOfClubOnDateBetween(
        clubId,
        date.toISOString(true),
        date.toISOString(true),
      );
      setLessonsWithManagerUser(fetchedLessons);
    } catch (e) {
      alert('레슨을 조회하는데 실패하였습니다.');
      console.log(e);
    }
  }, []);

  const refreshReservationsAndLessonsOfClub = useCallback(
    async (clubId: number, date: Moment) => {
      await Promise.all([fetchReservationsOfClub(clubId, date), fetchLessonsOfClub(clubId, date)]);
    },
    [fetchReservationsOfClub, fetchLessonsOfClub],
  );

  useEffect(() => {
    if (!isFetchingClubCourts && courtsByClub.length > 0 && !initiallyRenderedRef.current) {
      const initialClub = courtsByClub[0].club;
      setSelectedClub(initialClub);
      refreshReservationsAndLessonsOfClub(initialClub.id, selectedDate);
      initiallyRenderedRef.current = true;
    }
  }, [
    courtsByClub,
    refreshReservationsAndLessonsOfClub,
    isFetchingClubCourts,
    selectedClub?.id,
    selectedDate,
  ]);

  const onAddFabClick = () => {
    if (courtsByClub.length === 0) {
      alert('오류 - 일정을 생성할 구장이 없습니다');
      return;
    }
    toggleCreateDailyReservationModal();
  };

  const onRepeatFabClick = () => {
    if (courtsByClub.length === 0) {
      alert('오류 - 일정을 생성할 구장이 없습니다');
      return;
    }
    toggleCreatePeriodicReservationModal();
  };

  const onBlockFabClick = () => {
    toggleCreateDailyReservationBlockModal();
  };

  const onRepeatBlockFabClick = () => {
    toggleCreatePeriodicReservationBlockModal();
  };

  const handleSelectClubButtonClick = () => {
    setOpenListDialog(true);
  };

  const handleClubListItemClick = (clubName: string) => {
    setOpenListDialog(false);
    if (courtsByClub) {
      const selectResult = courtsByClub.find((it) => it.club.name === clubName);
      if (!selectResult) {
        return;
      }
      setSelectedClub(selectResult.club);
      refreshReservationsAndLessonsOfClub(selectResult.club.id, selectedDate);
    }
  };

  const handleSelectDateButtonClick = () => {
    setOpenCalendarDialog(true);
  };

  const handleDateSelectedEvent = (date: Moment | null) => {
    if (date) {
      setSelectedDate(date);
      if (selectedClub) {
        refreshReservationsAndLessonsOfClub(selectedClub.id, date);
      }
    }
    setOpenCalendarDialog(false);
  };

  const buttonStyle: SxProps = {
    backgroundColor: '#F2F3F5',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '15px',
    width: '165px',
    height: '44px',
    textAlign: 'center',
    color: '#000000',
  };

  const toggleCreateDailyReservationModal = () => {
    setIsCreateDailyReservationModalOpen((prev) => !prev);
  };

  const toggleCreatePeriodicReservationModal = () => {
    setIsCreatePeriodicReservationModalOpen((prev) => !prev);
  };

  const toggleCreateDailyReservationBlockModal = () => {
    setIsCreateDailyReservationBlockModalOpen((prev) => !prev);
  };
  const toggleCreatePeriodicReservationBlockModal = () => {
    setIsCreatePeriodicReservationBlockModalOpen((prev) => !prev);
  };

  const speedDialActions = [
    {
      icon: <AddIcon sx={{ color: 'black' }} onClick={onAddFabClick} />,
      name: '일일 일정 생성',
    },
    {
      icon: <RepeatIcon sx={{ color: 'black' }} onClick={onRepeatFabClick} />,
      name: '정기 일정 생성',
    },
    {
      icon: <BlockIcon sx={{ color: 'black' }} onClick={onBlockFabClick} />,
      name: '일일 예약 금지 생성',
    },
    {
      icon: <RepeatBlock onClick={onRepeatBlockFabClick} />,
      name: '정기 예약 금지 생성',
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        p: 3,
      }}
    >
      <Card title="일일 스케줄">
        <Box
          flexDirection="column"
          display="flex"
          alignItems="flex-end"
          marginTop="-40px"
          marginBottom="20px"
        >
          <Box>
            <Button onClick={handleSelectClubButtonClick} sx={{ ...buttonStyle, mr: '15px' }}>
              {selectedClub?.name}
              <ArrowDropDownIcon />
            </Button>
            <Button onClick={handleSelectDateButtonClick} sx={buttonStyle}>
              {selectedDate.locale('ko').format('yyyy. MM. DD (dd)')}
            </Button>
          </Box>
        </Box>

        <ReservationCalendar
          reservationsWithUser={selectedReservationsWithUser}
          lessonsWithManagerUser={lessonsWithManagerUser}
          courts={selectedCourts}
          selectedDate={selectedDate.toDate()}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      </Card>
      {/* Dialogs */}
      <ListDialog
        open={openListDialog}
        setOpen={setOpenListDialog}
        handleListItemClick={handleClubListItemClick}
        listItems={courtsByClub.map((item) => item.club.name)}
      />
      <Dialog
        open={openCalendarDialog}
        onBackdropClick={() => {
          setOpenCalendarDialog(false);
        }}
      >
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CalendarPicker
            minDate={minDate}
            date={selectedDate}
            onChange={handleDateSelectedEvent}
            views={['day']}
          />
        </LocalizationProvider>
      </Dialog>
      <Box>
        <SpeedDial
          ariaLabel="Toolbar SpeedDial"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          icon={<SpeedDialIcon sx={{ color: 'white' }} />}
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction key={action.name} tooltipTitle={action.name} icon={action.icon} />
          ))}
        </SpeedDial>
      </Box>
      {isCreateDailyReservationModalOpen && selectedClub && (
        <CreateDailyReservationModal
          open={isCreateDailyReservationModalOpen}
          toggleModal={toggleCreateDailyReservationModal}
          selectedClub={selectedClub}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
      {isCreatePeriodicReservationModalOpen && selectedClub && (
        <CreatePeriodicReservationModal
          open={isCreatePeriodicReservationModalOpen}
          toggleModal={toggleCreatePeriodicReservationModal}
          selectedClub={selectedClub}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
      {isCreateDailyReservationBlockModalOpen && selectedClub && (
        <CreateDailyReservationBlockModal
          open={isCreateDailyReservationBlockModalOpen}
          toggleModal={toggleCreateDailyReservationBlockModal}
          selectedClub={selectedClub}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
      {isCreatePeriodicReservationBlockModalOpen && selectedClub && (
        <CreatePeriodicReservationBlockModal
          open={isCreatePeriodicReservationBlockModalOpen}
          toggleModal={toggleCreatePeriodicReservationBlockModal}
          selectedClub={selectedClub}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
    </Box>
  );
};

export default ReservationManagementPage;
