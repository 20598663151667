import { getDateStringFromISODateString } from '../helper/dateHelper';
import requestGet from './helper/requestGet';
import { ReservationWithUser } from '../model/Reservation';

interface ReservationsAssociatedByClub {
  clubId: number;
  courtId: number;
  reservationPerDateList: ReservationPerDate[];
}

interface ReservationPerDate {
  date: string;
  reservations: ReservationWithUser[];
}

const getReservationsOfClubOnDateBetween = async (
  clubId: number,
  from: string,
  to: string,
): Promise<ReservationsAssociatedByClub[]> => {
  const endpoint = `/admin/clubs/${clubId}/reservations/times?from=${getDateStringFromISODateString(
    from,
  )}&to=${getDateStringFromISODateString(to)}`;

  return requestGet<ReservationsAssociatedByClub[]>(endpoint).then((res) => res.data);
};

export default getReservationsOfClubOnDateBetween;
