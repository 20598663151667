import axios, { AxiosInstance } from 'axios';
import serverDomain from '../../constant/apiConfig.const';

const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: serverDomain,
  headers: {
    'content-type': 'application/json',
  },
});

export const clearAccessToken = () => {
  axiosInstance.defaults.headers.common.Authorization = null;
};

export const setAccessToken = (accessToken: string) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default axiosInstance;
