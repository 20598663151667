import React from 'react';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { NotificationsNone, Report } from '@mui/icons-material';
import tennisBoxLogo from '../../assets/images/tennisbox_new_text_logo@2x.png';

const Header = () => {
  return (
    <AppBar sx={{ backgroundColor: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src={tennisBoxLogo} alt="tennis-box-logo" style={{ maxWidth: 130 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton size="large" edge="start" aria-label="report" sx={{ mr: 2, color: 'black' }}>
            <Report />
          </IconButton>
          <IconButton size="large" edge="start" aria-label="notifications" sx={{ color: 'black' }}>
            <NotificationsNone />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
