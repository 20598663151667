import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from './axiosInstance';

const requestPost = <D, T>(
  url: string,
  data: D,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => axiosInstance.post<T>(url, data, options);

export default requestPost;
