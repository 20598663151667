// Note: 이거 나중에 공통적으로 사용해도 좋을 것 같아서 위치 한번 봐주면 좋을듯
export const RouteType = {
  ReservationManagement: '/reservationManagement',
  ClubCourtManagement: '/clubCourtManagement',
} as const;

export type RouteType = typeof RouteType[keyof typeof RouteType];

export interface SideBarRouteItem {
  title: string;
  route: RouteType;
}
export const SIDE_BAR_ROUTE_LIST = [
  {
    title: '스케쥴 관리',
    route: RouteType.ReservationManagement,
  },
  {
    title: '구장 및 코트 관리',
    route: RouteType.ClubCourtManagement,
  },
];
