// YYYY년 MM월 DD일 형식
import moment, { Moment } from 'moment';

export const getKoreanDateFormatFromDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${year}년 ${month}월 ${day}일`;
};

// YYYY/MM/DD 형식
export const getSlashedDateFormatFromDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${year}/${month}/${day}`;
};

// YYYY. MM. DD 형식
export const getPointedDateFormatFromDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${year}. ${month}. ${day}`;
};

// YYYY-MM-DDTHH:MM:SS.sss+0900 => YYYY-MM-DDTHH:MM:SS
export const getDateStringFromISODateString = (isoDateString: string) => {
  return isoDateString.substring(0, 10);
};

// YYYY-MM-DDTHH:MM:SS.sss+0900 => YYYY-MM-DDTHH:MM:SS
export const getDateTimeStringFromISODateString = (isoDateString: string) => {
  return isoDateString.substring(0, 19);
};

export const dateStringToMoment = (isoDateString: string): Moment => {
  return moment(isoDateString, 'YYYY-MM-DD');
};

export const getWeekCntOfMonth = (date: Moment): number => {
  const firstDay = moment(date).startOf('month');
  const endDay = moment(date).endOf('month');
  return endDay.weeks() - firstDay.weeks() + 1;
};

export const momentToDateString = (date: Moment): string => {
  return date.toISOString(true).slice(0, 10);
};
