import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import moment, { Moment } from 'moment';
import { Checkbox } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CalendarPicker, LocalizationProvider } from '@mui/lab';
import { Theme } from '@mui/material/styles';
import axios, { AxiosError } from 'axios';
import { RootState } from '../../store';
import Court, { CourtsByClub } from '../../model/Court';
import { TimeBlocksWithDate } from '../../model/TimeBlocksWithDate';
import getCourtDailyOperationInfo from '../../api/getCourtDailyOperationInfo';
import {
  getReservationTimeBlockWithPreoccupied,
  getReservationTimeBlocksFromIndex,
} from '../../helper/getReservationTimeBlock';
import { TimeBlock } from '../../model/Time';
import ReservationTimeButton from '../../components/Button/ReservationTimeButton';
import { compareNumberAscending } from '../../helper/compareHelper';
import createDailyReservation from '../../api/createDailyReservation';
import { dateStringToMoment, getWeekCntOfMonth, momentToDateString } from '../../helper/dateHelper';
import { DuplicateReservationTimeException } from '../../model/Exception';
import { ManagerUser } from '../../model/ManagerUser';
import createDailyReservationAndAssignLesson from '../../api/createDailyReservationAndAssignLesson';
import ManagerUserAutoComplete from '../../components/Input/ManagerUserAutoComplete';
import Club from '../../model/Club';

interface Props {
  open: boolean;
  toggleModal: () => void;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void;
  selectedClub: Club;
}

const minDate = moment('2020-01-01T00:00:00.000');

export default function CreateDailyReservationModal({
  open,
  toggleModal,
  refreshReservationsAndLessonsOfClub,
  selectedClub,
}: Props) {
  const { courtsByClub } = useSelector((s: RootState) => s.ClubCourtReducer);
  const [selectedCourtsByClub, setSelectedCourtsByClub] = useState<CourtsByClub>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    courtsByClub.find((it) => it.club.id === selectedClub.id)!,
  );
  const [selectedCourt, setSelectedCourt] = useState<Court>(selectedCourtsByClub.courts[0]);
  const [isClubSelectDialogOpen, setIsClubSelectDialogOpen] = useState<boolean>(false);
  const [isCourtSelectDialogOpen, setIsCourtSelectDialogOpen] = useState<boolean>(false);

  /**
   * 모달 Input
   */
  const [title, setTitle] = useState<string>('일일 일정');
  const [userName, setUserName] = useState<string>('');
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');

  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState<boolean>(false);
  const [weekCntOfCalendarMonth, setWeekCntOfCalendarMonth] = useState<number>(5);

  const [timeBlocksWithDate, setTimeBlocksWithDate] = useState<TimeBlocksWithDate>({
    date: '',
    timeBlocks: [],
  });
  const [selectedTimeBlockIndexList, setSelectedTimeBlockIndexList] = useState<number[]>([]);
  const [tempSelectedTimeBlockIndexList, setTempSelectedTimeBlockIndexList] = useState<number[]>(
    [],
  );
  const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);

  const [isLessonChecked, setIsLessonChecked] = useState(false);
  const [selectedManagerUser, setSelectedManagerUser] = useState<ManagerUser | null>(null);

  const handleIsLessonCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setSelectedManagerUser(null);
    }
    setIsLessonChecked(event.target.checked);
  };

  useEffect(() => {
    fetchCourtPolicyAndSetReservationTimeBlocks(
      selectedCourtsByClub.club.id,
      selectedCourt.id,
      selectedDate,
    );
  }, [selectedCourt.id, selectedCourtsByClub.club.id, selectedDate]);

  const handleClose = () => toggleModal();

  const onClubSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetClubId = Number((event.target as HTMLInputElement).value);
    const targetCourtsByClub = courtsByClub.find((it) => it.club.id === targetClubId);
    if (targetCourtsByClub) {
      setSelectedCourtsByClub(targetCourtsByClub);
      setSelectedCourt(targetCourtsByClub.courts[0]);
    }
    setIsClubSelectDialogOpen(false);
  };

  const onCourtSelectChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const targetCourtId = Number((event.target as HTMLInputElement).value);
      const targetCourt = selectedCourtsByClub.courts.find((it) => it.id === targetCourtId);

      if (targetCourt) {
        setSelectedCourt(targetCourt);
        fetchCourtPolicyAndSetReservationTimeBlocks(
          selectedCourtsByClub.club.id,
          targetCourtId,
          selectedDate,
        );
      }

      setIsCourtSelectDialogOpen(false);
    } catch (e) {
      console.log(e);
      alert('네트워크 오류 - 코트를 다시 선택해주세요');
    }
  };

  const fetchCourtPolicyAndSetReservationTimeBlocks = async (
    clubId: number,
    courtId: number,
    date: Moment,
  ) => {
    try {
      const dateString = momentToDateString(date);

      const reservationTimeInfo = await getCourtDailyOperationInfo(clubId, courtId, dateString);

      setTimeBlocksWithDate({
        date: dateString,
        timeBlocks: getReservationTimeBlockWithPreoccupied(reservationTimeInfo, dateString),
      });
    } catch (e) {
      console.log(`fetchCourtPolicyAndSetReservationTimeBlocks error : ${e}`);
      alert('서버 오류. 죄송합니다.');
    }
  };

  const handleDateSelectedEvent = async (date: Moment | null) => {
    if (date) {
      setSelectedDate(date);
      fetchCourtPolicyAndSetReservationTimeBlocks(
        selectedCourtsByClub.club.id,
        selectedCourt.id,
        date,
      );
      setSelectedTimeBlockIndexList([]);
    }
    setIsCalendarPickerOpen(false);
  };

  const requestRegisterReservationAsLesson = async () => {
    if (selectedManagerUser === null) {
      alert('레슨 담당자를 설정해주세요');
      return;
    }

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocksWithDate.timeBlocks,
      selectedTimeBlockIndexList,
    );

    try {
      await createDailyReservationAndAssignLesson({
        clubId: selectedCourtsByClub.club.id,
        courtId: selectedCourt.id,
        date: momentToDateString(selectedDate),
        reservationTimeRangeList: [
          {
            startTime: selectedTimeBlocks[0].startTime,
            endTime: selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime,
          },
        ],
        managerUserId: selectedManagerUser.id,
        title,
        userName,
        userPhoneNumber,
      });

      await refreshReservationsAndLessonsOfClub(selectedCourtsByClub.club.id, selectedDate);
      toggleModal();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        alertDuplicateReservationTime(e);
        return;
      }
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  const requestCreateDailyReservation = async () => {
    if (title.length === 0) {
      alert('일정 제목을 입력해주세요');
      return;
    }

    if (selectedTimeBlockIndexList.length < 1) {
      alert('일정을 생성할 시간을 선택해주세요');
      return;
    }

    if (userName.length === 0) {
      alert('예약자 성함을 입력해주세요');
      return;
    }

    if (userPhoneNumber.length !== 11) {
      alert('핸드폰 번호 11자리를 모두 입력해주세요');
      return;
    }

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocksWithDate.timeBlocks,
      selectedTimeBlockIndexList,
    );

    try {
      await createDailyReservation({
        clubId: selectedCourtsByClub.club.id,
        courtId: selectedCourt.id,
        date: momentToDateString(selectedDate),
        reservationTimeRangeList: [
          {
            startTime: selectedTimeBlocks[0].startTime,
            endTime: selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime,
          },
        ],
        title,
        userName,
        userPhoneNumber,
      });

      await refreshReservationsAndLessonsOfClub(selectedCourtsByClub.club.id, selectedDate);
      toggleModal();
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        alertDuplicateReservationTime(e);
        return;
      }
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  const alertDuplicateReservationTime = (e: AxiosError) => {
    // Note: e.response.data.message 값이 존재하지 않는 경우에는 날짜를 뭉뚱그려서 표현
    if (!e.response?.data?.message) {
      alert('해당 날짜에 이미 일정이 존재합니다\n확인해주세요🤔');
      return;
    }

    const duplicateReservationTime: DuplicateReservationTimeException = JSON.parse(
      e.response.data.message,
    );
    const duplicateDate = dateStringToMoment(duplicateReservationTime.date);
    let alertMessage = `${duplicateDate.month()}월 ${duplicateDate.days()}일 ${
      duplicateReservationTime.startTime
    }에 이미 일정이 존재합니다.\n확인해주세요🤔`;
    if (moment().year() !== duplicateDate.year()) {
      alertMessage = `${duplicateDate.year()}년 ${alertMessage}`;
    }
    alert(alertMessage);
  };

  const onCreateButtonClick = async () => {
    if (isLessonChecked) {
      await requestRegisterReservationAsLesson();
    } else {
      await requestCreateDailyReservation();
    }
  };

  const handleDateButtonClick = () => {
    setIsCalendarPickerOpen((prevState) => !prevState);
  };

  const onTimeSelect = (index: number) => () => {
    setTempSelectedTimeBlockIndexList((prevList) => {
      if (prevList.includes(index)) {
        const indexOfSelectedIndex = prevList.findIndex((listItem) => listItem === index);
        if (indexOfSelectedIndex === 0) {
          return prevList.slice(1);
        }
        if (indexOfSelectedIndex === prevList.length - 1) {
          return prevList.slice(0, prevList.length - 1);
        }
        return [...prevList];
      }
      if (prevList.length >= 1) {
        if (
          Math.abs(prevList[0] - index) === 1 ||
          Math.abs(prevList[prevList.length - 1] - index) === 1
        ) {
          const nextList = [...prevList, index];
          nextList.sort(compareNumberAscending);
          return nextList;
        }
      }

      return [index];
    });
  };

  const getTimeSelectButtonContent = (): string => {
    if (selectedTimeBlockIndexList.length === 0) {
      return '선택 없음';
    }

    const selectedTimeBlocks = getReservationTimeBlocksFromIndex(
      timeBlocksWithDate.timeBlocks,
      selectedTimeBlockIndexList,
    );

    return `${selectedTimeBlocks[0].startTime}-${
      selectedTimeBlocks[selectedTimeBlocks.length - 1].endTime
    }`;
  };

  const handleTimeButtonClick = () => {
    setIsTimePickerOpen((prevState) => !prevState);
  };

  const handleTimeBlockCancelButtonClick = () => {
    setIsTimePickerOpen(false);
    setTempSelectedTimeBlockIndexList(selectedTimeBlockIndexList);
  };

  const handleTimeBlockConfirmButtonClick = () => {
    setSelectedTimeBlockIndexList(tempSelectedTimeBlockIndexList);
    setIsTimePickerOpen(false);
  };

  const handleTitleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleUserNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleUserPhoneNumberTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]{0,11}$/;
    if (regex.test(event.target.value)) {
      setUserPhoneNumber(event.target.value);
    }
  };

  const handleCalendarMonthChange = (firstDayOfMonth: Moment) => {
    setWeekCntOfCalendarMonth(getWeekCntOfMonth(firstDayOfMonth));
  };

  useEffect(() => {
    if (isTimePickerOpen) {
      if (selectedTimeBlockIndexList.length !== 0) {
        scrollToSelectedTimeBlocks();
      } else {
        scrollToNextTimeBlock();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimePickerOpen]);

  const scrollToSelectedTimeBlocks = () => {
    const selectedTimeBlock =
      timeBlocksWithDate.timeBlocks[
        selectedTimeBlockIndexList[Math.ceil((selectedTimeBlockIndexList.length - 1) / 2)]
      ];

    const timeBlockEl = document.getElementById(
      `reservation_time_${timeBlocksWithDate.date}_${selectedTimeBlock.startTime}`,
    );

    if (timeBlockEl) {
      timeBlockEl.scrollIntoView({ inline: 'center' });
    }
  };

  const scrollToNextTimeBlock = () => {
    const curMoment = moment();
    let curHour = curMoment.hour();
    let curMinute = curMoment.minute();
    if (curMinute > 30) {
      curHour += 1;
      curMinute = 0;
    } else {
      curMinute = 30;
    }

    const curHourInString = curHour < 10 ? `0${curHour}` : curHour;
    const curMinuteInString = curMinute < 10 ? `0${curMinute}` : curMinute;

    const timeBlockEl = document.getElementById(
      `reservation_time_${timeBlocksWithDate.date}_${curHourInString}:${curMinuteInString}`,
    );

    if (timeBlockEl) {
      timeBlockEl.scrollIntoView({ inline: 'center' });
    }
  };

  const handleSelectedManagerUser = (managerUser: ManagerUser | null) =>
    setSelectedManagerUser(managerUser);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: '30px' } }}
      >
        <Box sx={{ width: 400, padding: '27px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>일일 일정</Box>
          </Box>
          <Box style={{ marginTop: '32px' }}>
            <TextField
              required
              id="standard-required"
              label="일정 제목"
              onChange={handleTitleTextChange}
              value={title}
              variant="standard"
              fullWidth
              sx={{ fontSize: '20px' }}
            />
            <Box sx={{ marginTop: '20px', flexDirection: 'column' }}>
              <Box sx={{ position: 'relative', display: 'flex' }}>
                <Button
                  disabled
                  // TODO : disabled 의 제거 = 일일 일정 생성에서 구장 변경을 허용할 경우, 추가적인 UX 를 고려해야함
                  onClick={() => setIsClubSelectDialogOpen(true)}
                  sx={{ ...buttonStyle, mr: '15px' }}
                >
                  {selectedCourtsByClub.club.name}
                </Button>
                <Button
                  onClick={() => setIsCourtSelectDialogOpen(true)}
                  sx={isCourtSelectDialogOpen ? selectedButtonStyle : buttonStyle}
                >
                  {selectedCourt.name}
                </Button>
              </Box>
              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Box sx={{ width: '50%' }}>날짜</Box>

                <Box sx={{ position: 'absolute', right: 0 }}>
                  <Button
                    onClick={handleDateButtonClick}
                    sx={isCalendarPickerOpen ? selectedButtonStyle : buttonStyle}
                  >
                    {selectedDate.locale('ko').format('yyyy. MM. DD (dd)')}
                  </Button>
                </Box>
              </Box>

              {isCalendarPickerOpen && (
                <Box
                  sx={{
                    marginTop: '19px',
                  }}
                >
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <CalendarPicker
                      minDate={minDate}
                      date={selectedDate}
                      onChange={handleDateSelectedEvent}
                      onMonthChange={handleCalendarMonthChange}
                      views={['day']}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              <Box sx={getBoxStyleUnderCalendar(isCalendarPickerOpen, weekCntOfCalendarMonth)}>
                <Box style={{ width: '50%' }}>시간</Box>
                <Box style={{ position: 'absolute', right: 0 }}>
                  <Button
                    onClick={handleTimeButtonClick}
                    sx={isTimePickerOpen ? selectedButtonStyle : buttonStyle}
                  >
                    {getTimeSelectButtonContent()}
                  </Button>
                </Box>
              </Box>

              {isTimePickerOpen && (
                <Box sx={{ marginTop: '20px' }}>
                  <Box sx={{ fontSize: '0.7em', color: '#FF6392' }}>
                    💡 연속되지 않은 시간 선택 시, 이전 선택된 시간들은 선택 해제됩니다.
                  </Box>
                  <Box sx={{ marginTop: '10px', display: 'flex', overflowX: 'scroll' }}>
                    {timeBlocksWithDate.timeBlocks.map((timeBlock: TimeBlock, index: number) => {
                      const { startTime, disabled } = timeBlock;
                      return (
                        <ReservationTimeButton
                          key={`reservation_time_${timeBlocksWithDate.date}_${startTime}`}
                          id={`reservation_time_${timeBlocksWithDate.date}_${startTime}`}
                          timeText={`${startTime}`}
                          disabled={disabled}
                          selected={tempSelectedTimeBlockIndexList.includes(index)}
                          onPress={onTimeSelect(index)}
                        />
                      );
                    })}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleTimeBlockCancelButtonClick}>취소</Button>
                    <Button onClick={handleTimeBlockConfirmButtonClick}>확인</Button>
                  </Box>
                </Box>
              )}

              <Box sx={isTimePickerOpen ? {} : { marginTop: '25px' }}>
                <TextField
                  required
                  id="standard-required"
                  label="예약자 성함"
                  variant="standard"
                  onChange={handleUserNameTextChange}
                  value={userName}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: '25px' }}>
                <TextField
                  required
                  id="standard-required"
                  label="예약자 핸드폰 번호"
                  variant="standard"
                  onChange={handleUserPhoneNumberTextChange}
                  value={userPhoneNumber}
                  InputProps={{ type: 'text' }}
                  placeholder={"'-'를 제외한 숫자 11자리"}
                  helperText={"'-'를 제외한 숫자 11자리"}
                  fullWidth
                />
              </Box>

              <Box sx={{ marginTop: '10px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Checkbox checked={isLessonChecked} onChange={handleIsLessonCheckBoxClick} />
                  <Box>레슨인가요?</Box>
                </Box>
              </Box>

              {isLessonChecked &&
                (selectedManagerUser === null ? (
                  <ManagerUserAutoComplete
                    managerUserList={selectedCourtsByClub.managerUserList}
                    handleSelectedManagerUser={handleSelectedManagerUser}
                  />
                ) : (
                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>레슨 담당자</Box>

                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Button onClick={() => setSelectedManagerUser(null)} sx={buttonStyle}>
                        <img
                          width="20"
                          src={`${selectedManagerUser.profilePhotoPath}`}
                          alt="프로필사진"
                        />
                        <Box sx={{ ml: '10px' }}>{selectedManagerUser.name}</Box>
                      </Button>
                    </Box>
                  </Box>
                ))}

              <Box style={{ flexDirection: 'row', marginTop: '30px', position: 'relative' }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    backgroundColor: '#F2F3F5',
                    color: '#000000',
                    width: '47%',
                    boxShadow: 'none',
                    padding: '12px 16px',
                    fontSize: '0.855rem',
                    fontWeight: 'bold',
                  }}
                >
                  취소
                </Button>
                <Button
                  variant="contained"
                  onClick={onCreateButtonClick}
                  sx={{
                    backgroundColor: '#38B882',
                    color: '#FFFFFF',
                    position: 'absolute',
                    right: 0,
                    width: '47%',
                    boxShadow: 'none',
                    padding: '12px 16px',
                    fontSize: '0.855rem',
                    fontWeight: 'bold',
                  }}
                >
                  생성
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {isClubSelectDialogOpen && (
        <Dialog onClose={() => setIsClubSelectDialogOpen(false)} open={isClubSelectDialogOpen}>
          <DialogTitle>구장 선택</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup name="controlled-radio-buttons-group" onChange={onClubSelectChange}>
                {courtsByClub.map((it) => (
                  <FormControlLabel
                    key={it.club.id}
                    value={it.club.id}
                    control={<Radio />}
                    label={it.club.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
      {isCourtSelectDialogOpen && (
        <Dialog onClose={() => setIsCourtSelectDialogOpen(false)} open={isCourtSelectDialogOpen}>
          <DialogTitle>코트 선택</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup name="controlled-radio-buttons-group" onChange={onCourtSelectChange}>
                {selectedCourtsByClub.courts.map((court) => (
                  <FormControlLabel
                    key={court.id}
                    value={court.id}
                    control={<Radio />}
                    label={court.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

const buttonStyle: SystemStyleObject = {
  backgroundColor: '#F2F3F5',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '15px',
  width: '165px',
  height: '44px',
  textAlign: 'center',
  color: '#000000',
};

const selectedButtonStyle: SystemStyleObject = {
  ...buttonStyle,
  backgroundColor: '#FFFFFF',
  color: '#38B882',
  border: '0.7px solid #38B882',
};

const getBoxStyleUnderCalendar = (
  isCalendarOpen: boolean,
  weekCntOfCalendarMonth: number,
): SystemStyleObject<Theme> => {
  if (isCalendarOpen) {
    if (weekCntOfCalendarMonth === 6) {
      return {
        marginTop: '-20px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      };
    }

    return {
      marginTop: '-45px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    };
  }

  return {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  };
};
