import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import LoginPage from '../pages/Login/LoginPage';
import rootReducer from '../store';
import AuthGuardRouter from './AuthGuardRouter';

const store = createStore(rootReducer, applyMiddleware(thunk));
const theme = createTheme({
  palette: {
    primary: {
      main: '#38B882',
    },
    secondary: {
      main: '#11cb5f',
    },
    info: {
      main: '#38B882',
    },
    warning: {
      main: '#FF6392',
      contrastText: '#FFFFFF',
    },
  },
});

const RouterContainer = (): JSX.Element => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Route exact path="/" component={LoginPage} />
          <AuthGuardRouter />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
export default RouterContainer;
