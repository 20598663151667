import { AxiosResponse } from 'axios';
import { PeriodicReservationPreview, PeriodicReservationType } from '../model/PeriodicReservation';
import requestPost from './helper/requestPost';
import { TimeRange } from '../model/Time';

export interface RequestBody {
  clubId: number;
  startDate: string;
  endDate: string;
  reservations: ReservationPreviewPerCourt[];
}

interface ReservationPreviewPerCourt {
  courtId: number;
  repeatInterval: number;
  type: PeriodicReservationType;
  reservationTimeRangeList: TimeRange[];
  daysOfWeek: number[];
}

export interface ResponseBody {
  previews: PeriodicReservationPreview[];
}

const getPeriodicReservationPreview = (
  requestBody: RequestBody,
): Promise<AxiosResponse<ResponseBody>> => {
  const endpoint = `/admin/periodic-reservations/preview`;
  return requestPost<RequestBody, ResponseBody>(endpoint, {
    ...requestBody,
  });
};

export default getPeriodicReservationPreview;
