import { combineReducers } from 'redux';
import LoginReducer from './models/login';
import ClubCourtReducer from './models/clubCourt';

const rootReducer = combineReducers({
  LoginReducer,
  ClubCourtReducer,
});
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
