import { getDateStringFromISODateString } from '../helper/dateHelper';
import requestGet from './helper/requestGet';
import { LessonWithManagerUser } from '../model/Lesson';

const getReservationsOfClubOnDateBetween = async (
  clubId: number,
  from: string,
  to: string,
): Promise<LessonWithManagerUser[]> => {
  const endpoint = `/admin/clubs/${clubId}/lessons?from=${getDateStringFromISODateString(
    from,
  )}&to=${getDateStringFromISODateString(to)}`;

  return requestGet<LessonWithManagerUser[]>(endpoint).then((res) => res.data);
};

export default getReservationsOfClubOnDateBetween;
