import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from './axiosInstance';

const requestPatch = <D, T>(
  url: string,
  data?: D,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => axiosInstance.patch<T>(url, data, options);

export default requestPatch;
