import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Moment } from 'moment';
import { SystemStyleObject } from '@mui/system';
import { dateStringToMoment } from '../../helper/dateHelper';
import { ReservationWithUser } from '../../model/Reservation';
import { LessonWithManagerUser } from '../../model/Lesson';
import removeLessonFromReservation from '../../api/removeLessonFromReservation';

interface Props {
  open: boolean;
  toggleModal: () => void;
  selectedReservationWithUser: ReservationWithUser;
  selectedLessonWithManagerUser: LessonWithManagerUser;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void; // For Refresh Usage
}

export default function RevokeLessonModal({
  open,
  toggleModal,
  selectedReservationWithUser,
  selectedLessonWithManagerUser,
  refreshReservationsAndLessonsOfClub,
}: Props) {
  const handleClose = () => toggleModal();

  const onDeleteButtonClick = async () => {
    try {
      await removeLessonFromReservation(selectedReservationWithUser.reservationId);

      await refreshReservationsAndLessonsOfClub(
        selectedReservationWithUser.clubId,
        dateStringToMoment(selectedReservationWithUser.date),
      );
      toggleModal();
    } catch (e) {
      console.log(e);
      alert('서버 오류. 죄송합니다.');
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{ sx: { borderRadius: '30px' } }}
      >
        <Box sx={{ width: 400, padding: '27px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box style={{ fontSize: '21px', fontWeight: 'bold' }}>레슨 해제</Box>
          </Box>

          <Box sx={{ marginTop: '21px' }}>정말로 레슨을 해제하시겠습니까?</Box>
          <Box sx={{ fontSize: '0.8em', color: '#38B882' }}>💡 일정은 삭제되지 않습니다.</Box>
          <Box style={{ marginTop: '32px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box sx={{ width: '50%' }}>레슨 담당자</Box>

              <Box sx={{ position: 'absolute', right: 0 }}>
                <Box sx={buttonLikeBoxStyle}>
                  <img
                    width="20"
                    src={`${selectedLessonWithManagerUser.managerUser.profilePhotoPath}`}
                    alt=""
                  />
                  <Box sx={{ ml: '10px' }}>{selectedLessonWithManagerUser.managerUser.name}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ flexDirection: 'row', marginTop: '55px', position: 'relative' }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: '#F2F3F5',
                color: '#000000',
                width: '47%',
                boxShadow: 'none',
                padding: '12px 16px',
                fontSize: '0.855rem',
                fontWeight: 'bold',
              }}
            >
              닫기
            </Button>
            <Button
              variant="contained"
              onClick={onDeleteButtonClick}
              color="warning"
              sx={{
                position: 'absolute',
                right: 0,
                width: '47%',
                boxShadow: 'none',
                padding: '12px 16px',
                fontSize: '0.855rem',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              해제
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

const buttonStyle: SystemStyleObject = {
  backgroundColor: '#F2F3F5',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '15px',
  width: '165px',
  height: '44px',
  textAlign: 'center',
  color: '#000000',
};

const buttonLikeBoxStyle: SystemStyleObject = {
  ...buttonStyle,
  backgroundColor: 'white',
  color: 'black',
  border: 'solid 1px #DDDDDD',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
  letterSpacing: '0.02857em',
  minWidth: '64px',
  padding: '6px 8px',
  borderRadius: '4px',
  display: 'flex',
};
