import Box from '@mui/material/Box';
import {
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
} from '@mui/material';
import MonitorIcon from '@mui/icons-material/Monitor';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { LessonWithManagerUser } from '../../model/Lesson';

export interface ReservationEventSelectPopupProps {
  setOpen: (open: boolean) => void;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleReservationDetailViewClick: () => void;
  handleReservationDeleteClick: () => void;
  handleLessonAssignClick: () => void;
  handleLessonRevokeClick: () => void;
  selectedLessonWithManagerUser: LessonWithManagerUser | null;
}

const ReservationEventSelectPopup = ({
  setOpen,
  open,
  anchorEl,
  handleReservationDetailViewClick,
  handleReservationDeleteClick,
  handleLessonAssignClick,
  handleLessonRevokeClick,
  selectedLessonWithManagerUser,
}: ReservationEventSelectPopupProps): null | JSX.Element => {
  const transitionTimeout = 200;

  useEffect(() => {
    // HACK : Transition 시간 이후에, zIndex 설정해야 먹힘
    if (open) {
      setTimeout(() => {
        const popper = document.getElementById('reservationEventSelectPopUp');
        if (popper) {
          popper.style.zIndex = '10';
        }
      }, transitionTimeout);
    }
  }, [open]);

  const isSelectedReservationLesson = () => selectedLessonWithManagerUser !== null;

  return (
    anchorEl && (
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <Popper id="reservationEventSelectPopUp" open={open} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Fade {...TransitionProps} timeout={transitionTimeout}>
              <Box
                sx={{
                  position: 'absolute',
                  width: '180px',
                  borderRadius: '19px',
                  backgroundColor: 'white',
                  boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              >
                <List>
                  <ListItemButton onClick={handleReservationDetailViewClick}>
                    <ListItemIcon>
                      <MonitorIcon color="info" />
                    </ListItemIcon>
                    <ListItemText primary="상세 보기" />
                  </ListItemButton>
                  {isSelectedReservationLesson() ? (
                    <>
                      <ListItemButton onClick={handleLessonRevokeClick}>
                        <ListItemIcon>
                          <PersonOffIcon color="warning" />
                        </ListItemIcon>
                        <ListItemText primary="레슨 해제" />
                      </ListItemButton>
                    </>
                  ) : (
                    <>
                      <ListItemButton onClick={handleLessonAssignClick}>
                        <ListItemIcon>
                          <PersonAddAlt1Icon htmlColor="#3182F5" />
                        </ListItemIcon>
                        <ListItemText primary="레슨 할당" />
                      </ListItemButton>
                    </>
                  )}
                  <ListItemButton onClick={handleReservationDeleteClick}>
                    <ListItemIcon>
                      <CloseIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="일정 삭제" />
                  </ListItemButton>
                </List>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    )
  );
};

export default ReservationEventSelectPopup;
