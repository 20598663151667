import { AxiosError } from 'axios';
import { clearAccessToken, setAccessToken } from '../../api/helper/axiosInstance';
import { REFRESH_TOKEN } from '../../constant/tokenType';
import postRefresh from '../../api/postRefresh';
import postSignin from '../../api/postSignIn';
import { removeLocalStorage, setLocalStorage } from '../../helper/localStorage';
import { tennisAdminAPIClient } from '../../libs/tennis-admin-api/TennisAdminAPIClientImpl';

export const ActionType = {
  LOGIN_START: 'api/user/login/start',
  LOGIN_SUCCESS: 'api/user/login/success',
  LOGIN_FAILURE: 'api/user/login/failure',
  LOGOUT: 'LOGOUT',
} as const;

type ActionTypeDef = typeof ActionType[keyof typeof ActionType];

export const login = (accountId: string, password: string, autoLogin: boolean) => {
  return async (
    dispatch: (param: { type: ActionTypeDef; payload?: AxiosError }) => Promise<void>,
  ) => {
    dispatch(loginStartAction());

    try {
      const loginResponse = await postSignin(accountId, password);
      const { accessToken, refreshToken } = loginResponse.data;

      setAccessToken(accessToken);
      if (autoLogin) {
        // save refresh token to local stroage
        setLocalStorage(REFRESH_TOKEN, refreshToken);
      }

      dispatch(loginSuccessAction());
    } catch (e: unknown) {
      dispatch(loginFailureAction(e as AxiosError));
    }
  };
};

export const refresh = (refreshToken: string) => {
  return async (
    dispatch: (param: { type: ActionTypeDef; payload?: AxiosError }) => Promise<void>,
  ) => {
    dispatch(loginStartAction());

    try {
      const refreshResponse = await postRefresh(refreshToken);
      const { accessToken } = refreshResponse.data;

      tennisAdminAPIClient.setAccessToken(accessToken);
      dispatch(loginSuccessAction());
    } catch (e: unknown) {
      dispatch(loginFailureAction(e as AxiosError));
    }
  };
};

export const logout = () => {
  return (dispatch: (param: { type: ActionTypeDef; payload?: AxiosError }) => void) => {
    clearAccessToken();
    removeLocalStorage(REFRESH_TOKEN);

    dispatch(logoutAction());
  };
};

const loginStartAction = () => {
  return {
    type: ActionType.LOGIN_START,
  };
};

const loginSuccessAction = () => {
  return {
    type: ActionType.LOGIN_SUCCESS,
  };
};

export const loginFailureAction = (payload: AxiosError) => {
  return {
    type: ActionType.LOGIN_FAILURE,
    payload,
  };
};

const logoutAction = () => {
  return {
    type: ActionType.LOGOUT,
  };
};

type UserActionType = ReturnType<
  | typeof loginStartAction
  | typeof loginSuccessAction
  | typeof loginFailureAction
  | typeof logoutAction
>;

interface UserState {
  isFetching: boolean;
  isLoggedIn: boolean;
  error: any;
}

const initialState: UserState = {
  isFetching: false,
  isLoggedIn: false,
  error: null,
};

const reducer = (state: UserState = initialState, action: UserActionType): UserState => {
  switch (action.type) {
    case ActionType.LOGIN_START:
      return {
        isFetching: true,
        isLoggedIn: false,
        error: null,
      };

    case ActionType.LOGIN_SUCCESS:
      return {
        isFetching: false,
        isLoggedIn: true,
        error: null,
      };

    case ActionType.LOGIN_FAILURE:
      return {
        isFetching: false,
        isLoggedIn: false,
        error: action.payload,
      };

    case ActionType.LOGOUT:
      return {
        isFetching: false,
        isLoggedIn: false,
        error: null,
      };

    default:
      return state;
  }
};
export default reducer;
