import { AxiosResponse } from 'axios';
import requestPost from './helper/requestPost';
import { TimeRange } from '../model/Time';

interface RequestBody {
  clubId: number;
  courtId: number;
  date: string;
  reservationTimeRangeList: TimeRange[];
  title: string;
}

const blockDailyReservation = (requestBody: RequestBody): Promise<AxiosResponse<null>> => {
  const endpoint = '/admin/reservations/block';

  return requestPost<RequestBody, null>(endpoint, {
    ...requestBody,
  });
};

export default blockDailyReservation;
