import * as React from 'react';
import Box from '@mui/material/Box';

interface Props {
  title: string;
  children: any;
}

export default function Card({ title, children }: Props) {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #DDDDDD',
        borderRadius: 10,
        boxShadow: '0px 0px 4px RGBA(0,0,0, 0.2)',
      }}
    >
      <div style={{ fontSize: 24, fontWeight: 800, marginLeft: 43, marginTop: 33 }}>{title}</div>
      <div style={{ padding: '0px 30px 30px 30px' }}>{children}</div>
    </Box>
  );
}
