import React, { useEffect } from 'react';
import { Box, Container, CssBaseline, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import ReservationManagementPage from '../pages/ReservationManagement/ReservationManagementPage';
import { RootState } from '../store';
import ClubCourtManagement from '../pages/ClubCourtManagement/ClubCourtManagement';
import { fetchMyClubCourts } from '../store/models/clubCourt';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';

/*
 * Note: 로그인 이후 접근 가능한 라우터 모음
 * 대부분의 라우터는 여기에 추가하면 됨!
 */
const AuthGuardRouter = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.LoginReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) return;

    dispatch(fetchMyClubCourts());
  }, [isLoggedIn, dispatch]);

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container
      component="main"
      disableGutters
      maxWidth={false}
      sx={{ display: 'flex', flex: 1, width: '100%', height: '100%' }}
    >
      <CssBaseline />
      {/* Header: position: fixed, 상단에서 <Toolbar> 사이즈 만큼 영역 차지 */}
      <Header />
      {/* Sidebar: position: fixed, 좌측에서 DRAWER_WIDTH 만큼 영역 차지 */}
      <Sidebar />

      {/* 컨텐츠 영역: Sidebar와 병렬 배치로 자동으로 좌측 DRAWER_WIDTH 만큼 제외 + <Toolbar> 로 상단 spacing */}
      <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Toolbar /> {/* for spacing */}
        {/* 결과적으로 해당 BOX(컨텐츠 영역) 은 온전하게 나머지 전체 영역을 차지할 수 있음 */}
        {/* Route로 들어가는 컴포넌트의 가장 바깥 컨테이너를 flex:1 처리하면 전체 영역을 차지함 */}
        <Box style={{ flex: 1, display: 'flex', backgroundColor: '#F5F5F5', overflowY: 'auto' }}>
          {/* NOTE: 해당 위치에 route 추가 */}
          <Route path="/reservationManagement" component={ReservationManagementPage} />
          <Route path="/clubCourtManagement" component={ClubCourtManagement} />
        </Box>
      </Box>
    </Container>
  );
};
export default AuthGuardRouter;
