import {
  AdminClubApi,
  AdminCourtApi,
  AdminLessonApi,
  AdminReservationApi,
  AdminUtilApi,
  Configuration,
  ExecutiveClubApi,
  ExecutiveReservationApi,
} from '@gnd3/tennis-admin-api';
import { TennisAdminAPIClient } from './TennisAdminAPIClient';
import serverDomain from '../../constant/apiConfig.const';

export class TennisAdminAPIClientImpl implements TennisAdminAPIClient {
  setAccessToken(accessToken: string) {
    const config = new Configuration({
      // apiKey: `bearer ${accessToken}`,
      accessToken,
      basePath: serverDomain,
    });

    this.club = new AdminClubApi(config);
    this.court = new AdminCourtApi(config);
    this.executiveClub = new ExecutiveClubApi(config);
    this.executiveReservation = new ExecutiveReservationApi(config);
    this.lesson = new AdminLessonApi(config);
    this.reservation = new AdminReservationApi(config);
    this.util = new AdminUtilApi(config);
  }

  club = new AdminClubApi();

  court = new AdminCourtApi();

  executiveClub = new ExecutiveClubApi();

  executiveReservation = new ExecutiveReservationApi();

  lesson = new AdminLessonApi();

  reservation = new AdminReservationApi();

  util = new AdminUtilApi();
}

export const tennisAdminAPIClient = new TennisAdminAPIClientImpl();
