import { AxiosError } from 'axios';
import getMyclub from '../../api/getMyClub';
import { CourtsByClub } from '../../model/Court';

export const ActionType = {
  FETCH_CLUB_COURT_START: 'api/clubCourt/fetch/start',
  FETCH_CLUB_COURT_SUCCESS: 'api/clubCourt/fetch/success',
  FETCH_CLUB_COURT_FAILURE: 'api/clubCourt/fetch/failure',
} as const;

type ActionTypeDef = typeof ActionType[keyof typeof ActionType];

export const fetchMyClubCourts = () => {
  return async (
    dispatch: (param: {
      type: ActionTypeDef;
      payload?: AxiosError | CourtsByClub[];
    }) => Promise<void>,
  ) => {
    dispatch(fetchClubCourtStartAction());

    try {
      const response = await getMyclub();

      dispatch(fetchClubCourtSuccessAction(response));
    } catch (e: unknown) {
      dispatch(fetchClubCourtFailureAction(e as AxiosError));
    }
  };
};

const fetchClubCourtStartAction = () => {
  return {
    type: ActionType.FETCH_CLUB_COURT_START,
  };
};

const fetchClubCourtSuccessAction = (payload: CourtsByClub[]) => {
  return {
    type: ActionType.FETCH_CLUB_COURT_SUCCESS,
    payload,
  };
};

const fetchClubCourtFailureAction = (payload: AxiosError) => {
  return {
    type: ActionType.FETCH_CLUB_COURT_FAILURE,
    payload,
  };
};

type ClubCourtActionType = ReturnType<
  | typeof fetchClubCourtStartAction
  | typeof fetchClubCourtSuccessAction
  | typeof fetchClubCourtFailureAction
>;

interface ClubCourtState {
  isFetching: boolean;
  courtsByClub: CourtsByClub[];
  error: any;
}

const initialState: ClubCourtState = {
  isFetching: false,
  courtsByClub: [] as CourtsByClub[],
  error: null,
};

const reducer = (
  state: ClubCourtState = initialState,
  action: ClubCourtActionType,
): ClubCourtState => {
  switch (action.type) {
    case ActionType.FETCH_CLUB_COURT_START:
      return {
        isFetching: true,
        courtsByClub: [] as CourtsByClub[],
        error: null,
      };

    case ActionType.FETCH_CLUB_COURT_SUCCESS:
      return {
        isFetching: false,
        courtsByClub: action.payload,
        error: null,
      };

    case ActionType.FETCH_CLUB_COURT_FAILURE:
      return {
        isFetching: false,
        courtsByClub: [] as CourtsByClub[],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
