import React from 'react';
import Button from '@mui/material/Button';
import { SystemStyleObject } from '@mui/system';

import { Theme } from '@mui/material';

interface Props {
  disabled?: boolean;
  selected?: boolean;
  timeText: string;
  onPress: () => void;
  id: string;
}

const getReservationButtonStyle = (
  disabled: boolean,
  selected: boolean,
): SystemStyleObject<Theme> => {
  if (disabled) {
    return reservationTimeButtonStyles.disabled;
  }

  if (selected) {
    return reservationTimeButtonStyles.selected;
  }

  return reservationTimeButtonStyles.default;
};
const ReservationTimeButton = ({
  disabled = false,
  selected = false,
  timeText,
  onPress,
  id,
}: Props) => {
  const style = getReservationButtonStyle(disabled, selected);

  return (
    <Button
      id={id}
      disabled={disabled}
      sx={disabled ? { ...style, textDecoration: 'line-through' } : style}
      onClick={onPress}
    >
      {timeText}
    </Button>
  );
};
export default ReservationTimeButton;

export const reservationTimeButtonStyles = {
  default: {
    minWidth: 50,
    // width: '23%',
    marginRight: 1,
    marginBottom: 2,
    height: 37,
    border: 'solid 0.8px',
    borderRadius: 1,
    borderColor: '#636363',
    backgroundColor: 'white',
    fontSize: 11,
    color: '#000000',
  },
  selected: {
    minWidth: 50,
    // width: '23%',
    marginRight: 1,
    marginBottom: 2,
    height: 37,
    borderRadius: 1,
    backgroundColor: '#38B882',
    fontSize: 11,
    color: 'white',
  },
  disabled: {
    minWidth: 50,
    // width: '23%',
    marginRight: 1,
    marginBottom: 2,
    border: 'solid 0.8px',
    borderColor: '#c4c4c4',
    backgroundColor: 'white',
    borderRadius: 1,
    color: '#c4c4c4',
    fontSize: 11,
  },
};
