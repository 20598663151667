import React from 'react';
import { Dialog, List, ListItem, ListItemText } from '@mui/material';

export interface SimpleDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleListItemClick: (value: string) => void;
  listItems: string[];
}

const ListDialog = (props: SimpleDialogProps) => {
  const { open, setOpen, handleListItemClick, listItems } = props;

  return (
    <Dialog open={open} onBackdropClick={() => setOpen(false)}>
      <List sx={{ pt: 0 }}>
        {listItems.map((item) => (
          <ListItem
            button
            onClick={() => {
              handleListItemClick(item);
            }}
            key={item}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default ListDialog;
