import React, { ComponentType, SyntheticEvent, useEffect, useState } from 'react';
import { Calendar, CalendarProps, Event, momentLocalizer } from 'react-big-calendar';
import moment, { Moment } from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Court from '../../model/Court';
import { ReservationType, ReservationWithUser } from '../../model/Reservation';
import ReservationEventSelectPopup from '../Popup/ReservationEventSelectPopup';
import DailyReservationDetailModal from '../../pages/ReservationManagement/DailyReservationDetailModal';
import './ReservationCalendar.css';
import { LessonWithManagerUser } from '../../model/Lesson';
import AssignLessonModal from '../../pages/ReservationManagement/AssignLessonModal';
import RevokeLessonModal from '../../pages/ReservationManagement/RevokeLessonModal';
import CancelReservationModal from '../../pages/ReservationManagement/CancelReservationModal';

const localizer = momentLocalizer(moment);

export interface ReservationEvent extends Event {
  clubId: number;
  courtId: number;
  start: Date;
  end: Date;
  title: string;
  reservationId: number;
  type: ReservationType;
}
export interface CourtResource {
  courtId: number;
  courtName: string;
}

const DnDCalendar = withDragAndDrop(Calendar as ComponentType<CalendarProps>);

interface Props {
  reservationsWithUser: ReservationWithUser[];
  lessonsWithManagerUser: LessonWithManagerUser[];
  courts: Court[];
  selectedDate: Date;
  refreshReservationsAndLessonsOfClub: (clubId: number, date: Moment) => void; // For Refresh Usage
}

const convertToDate = (dateString: string, timeString: string): Date => {
  return moment(`${dateString}T${timeString}`).toDate();
};

const ReservationCalendar = ({
  reservationsWithUser,
  lessonsWithManagerUser,
  courts,
  selectedDate,
  refreshReservationsAndLessonsOfClub,
}: Props) => {
  const [courtResources, setCourtResources] = useState<CourtResource[]>([]);
  const [reservationEvents, setReservationEvents] = useState<ReservationEvent[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedReservationWithUser, setSelectedReservationWithUser] =
    useState<ReservationWithUser | null>(null);
  const [selectedLessonWithManagerUser, setSelectedLessonWithManagerUser] =
    useState<LessonWithManagerUser | null>(null);
  const [isDailyReservationDetailModalOpen, setIsDailyReservationDetailModalOpen] =
    useState<boolean>(false);
  const [isCancelReservationModalOpen, setIsCancelReservationModalOpen] = useState<boolean>(false);
  const [isAssignLessonModalOpen, setIsAssignLessonModalOpen] = useState<boolean>(false);
  const [isRevokeLessonModalOpen, setIsRevokeLessonModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setCourtResources(
      courts.map((court) => {
        return { courtId: court.id, courtName: court.name };
      }),
    );
  }, [courts]);

  useEffect(() => {
    if (reservationsWithUser.length > 0 && reservationsWithUser[0]) {
      setReservationEvents(
        reservationsWithUser.map((reservation) => {
          return {
            clubId: reservation.clubId,
            courtId: reservation.courtId,
            start: convertToDate(reservation.date, reservation.reservationTimeRange.startTime),
            end: convertToDate(reservation.date, reservation.reservationTimeRange.endTime),
            title: reservation.title,
            reservationId: reservation.reservationId,
            type: reservation.type,
          };
        }),
      );
    } else {
      setReservationEvents([]);
    }
  }, [reservationsWithUser]);

  const eventPropGetter = (event: any) =>
    // eslint-disable-next-line @typescript-eslint/ban-types
    // event: Object,
    // start: stringOrDate,
    // end: stringOrDate,
    // isSelected: boolean,
    {
      const reservationEvent = event as ReservationEvent;

      const newStyle = {
        backgroundColor: '#54C4DD',
        color: 'white',
        border: '2px solid #000000',
      };

      const lesson = lessonsWithManagerUser.find(
        (it) => it.reservationId === reservationEvent.reservationId,
      );
      if (lesson) {
        if (lesson.hexColor.length > 0) {
          newStyle.backgroundColor = lesson.hexColor;
        } else {
          newStyle.backgroundColor = '#38B882';
        }
      }

      // TODO : if(event == 정기예약) {newStyle.backgroundColor = '#FF9F2F'}

      return {
        className: '',
        style: newStyle,
      };
    };

  const onSelectEvent = (event: any, e: SyntheticEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const reservationEvent = event as ReservationEvent;
    const reservation = reservationsWithUser.find(
      (it) => it.reservationId === reservationEvent.reservationId,
    );
    if (reservation) {
      setSelectedReservationWithUser(reservation);

      const lessonWithManagerUser = lessonsWithManagerUser.find(
        (it) => it.reservationId === reservation.reservationId,
      );
      if (lessonWithManagerUser) {
        setSelectedLessonWithManagerUser(lessonWithManagerUser);
      } else {
        setSelectedLessonWithManagerUser(null);
      }
    }

    if (showPopup) {
      if (anchorEl === (e.currentTarget as HTMLElement)) {
        // 동일한 일정을 클릭한 경우
        setAnchorEl(null);
        setShowPopup(false);
      } else {
        // 다른 일정을 클릭한 경우
        setAnchorEl(e.currentTarget as HTMLElement);
        setShowPopup(true);
        // ClickAwayListener 이벤트 전파 방지용
        e.stopPropagation();
      }
    } else {
      setAnchorEl(e.currentTarget as HTMLElement);
      setShowPopup(true);
    }
  };

  const handleReservationDetailViewClick = () => {
    if (selectedReservationWithUser === null) {
      return;
    }

    switch (selectedReservationWithUser.type) {
      case 'BLOCKED':
        // TODO
        break;

      case 'DAILY':
        toggleDailyReservationDetailModal();
        break;

      case 'PERIODIC':
        // TODO
        break;

      default:
        // error
        break;
    }
  };

  const handleReservationDeleteClick = () => {
    // TODO : 일정 삭제 모달 붙이기
    // TODO : 레슨일 경우, API 다르게
    if (selectedReservationWithUser === null) {
      return;
    }

    switch (selectedReservationWithUser.type) {
      case 'BLOCKED':
        // TODO
        break;

      case 'DAILY':
        toggleCancelReservationModal();
        break;

      case 'PERIODIC':
        // TODO
        break;

      default:
        // error
        break;
    }
  };

  const toggleDailyReservationDetailModal = () => {
    setIsDailyReservationDetailModalOpen((prev) => !prev);
  };

  const toggleAssignLessonModal = () => setIsAssignLessonModalOpen((prev) => !prev);

  const toggleRevokeLessonModal = () => setIsRevokeLessonModalOpen((prev) => !prev);

  const toggleCancelReservationModal = () => setIsCancelReservationModalOpen((prev) => !prev);

  return (
    <>
      <DnDCalendar
        selectable
        localizer={localizer}
        resizable
        defaultDate={moment().toDate()}
        date={selectedDate}
        onNavigate={() => ({})}
        toolbar={false}
        views={['day']}
        defaultView="day"
        events={reservationEvents}
        resourceAccessor={(reservationEvent: any) => (reservationEvent as ReservationEvent).courtId}
        resources={courtResources}
        resourceIdAccessor={(resource: any) => (resource as CourtResource).courtId}
        resourceTitleAccessor={(resource: any) => (resource as CourtResource).courtName}
        style={{ height: '100%', width: '100%' }}
        eventPropGetter={eventPropGetter}
        slotPropGetter={() => {
          return { style: { backgroundColor: '#FFFFFF' } };
        }}
        slotGroupPropGetter={() => {
          return { style: { backgroundColor: '#FFFFFF' } };
        }}
        dayPropGetter={() => {
          return { style: { backgroundColor: '#FFFFFF' } };
        }}
        onSelectEvent={onSelectEvent}
      />
      {showPopup && (
        <ReservationEventSelectPopup
          setOpen={setShowPopup}
          open={showPopup}
          anchorEl={anchorEl}
          handleReservationDetailViewClick={handleReservationDetailViewClick}
          handleReservationDeleteClick={handleReservationDeleteClick}
          handleLessonAssignClick={toggleAssignLessonModal}
          handleLessonRevokeClick={toggleRevokeLessonModal}
          selectedLessonWithManagerUser={selectedLessonWithManagerUser}
        />
      )}
      {isDailyReservationDetailModalOpen && selectedReservationWithUser && (
        <DailyReservationDetailModal
          open={isDailyReservationDetailModalOpen}
          toggleModal={toggleDailyReservationDetailModal}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
          selectedReservationWithUser={selectedReservationWithUser}
          selectedLessonWithManagerUser={selectedLessonWithManagerUser}
          globalSelectedDate={selectedDate}
        />
      )}
      {isAssignLessonModalOpen && selectedReservationWithUser && (
        <AssignLessonModal
          open={isAssignLessonModalOpen}
          toggleModal={toggleAssignLessonModal}
          selectedReservationWithUser={selectedReservationWithUser}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
      {isRevokeLessonModalOpen && selectedReservationWithUser && selectedLessonWithManagerUser && (
        <RevokeLessonModal
          open={isRevokeLessonModalOpen}
          toggleModal={toggleRevokeLessonModal}
          selectedReservationWithUser={selectedReservationWithUser}
          selectedLessonWithManagerUser={selectedLessonWithManagerUser}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
      {isCancelReservationModalOpen && selectedReservationWithUser && (
        <CancelReservationModal
          open={isCancelReservationModalOpen}
          toggleModal={toggleCancelReservationModal}
          selectedReservationWithUser={selectedReservationWithUser}
          selectedLessonWithManagerUser={selectedLessonWithManagerUser}
          refreshReservationsAndLessonsOfClub={refreshReservationsAndLessonsOfClub}
        />
      )}
    </>
  );
};

export default ReservationCalendar;
