import CourtDailyOperationInfo from '../model/CourtDailyOperationInfo';
import requestGet from './helper/requestGet';

const getCourtDailyOperationInfo = async (
  clubId: number,
  courtId: number,
  dateString: string,
): Promise<CourtDailyOperationInfo> => {
  const endpoint = `/clubs/${clubId}/courts/${courtId}/reservations/times?dateString=${dateString}`;
  return requestGet<CourtDailyOperationInfo>(endpoint).then((res) => res.data);
};

export default getCourtDailyOperationInfo;
